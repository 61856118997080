import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import {
  Home,
  Info,
  Settings,
  AccountCircle,
  Notifications,
  Help,
  ExitToApp,
} from "@mui/icons-material";
import { LineChart } from "@mui/x-charts"; // Importing LineChart from @mui/x-charts
import "./index.scss";
import PerformanceService from "../../services/PerformanceService";
import AppHeader from "../../components/AppHeader";
import DrawerNavigation from "../Dashboard/DrawerNavigation";
import { BarChart } from "@mui/x-charts/BarChart";
import LoaderContext from "../../context/LoaderContext";

const PerformancePage = ({ onLogout }: any) => {
  const [performance, setPerformance] = useState<any | null>(null);
  const { loading, setLoading } = useContext(LoaderContext);

  useEffect(() => {
    fetchPerformanceData();
  }, []);

  const fetchPerformanceData = async () => {
    setLoading(true);
    try {
      const data = await PerformanceService.getPerformance();
      setPerformance(data);
    } catch (error) {
      console.error("Error fetching performance data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!performance) {
    return <div>No data available</div>;
  }

  const activitySeries = [
    {
      id: "activity",
      data: performance.activity.map((a: any) => ({
        x: new Date(a.date),
        y: a.timeSpent,
      })),
      label: "Avg time spent per task over time",
      color: "rgba(75, 192, 192, 1)",
    },
  ];

  const statisticsSeries = [
    {
      id: "statistics",
      data: performance.statistics.map((s: any) => ({
        x: new Date(s.date),
        y: s.workload,
      })),
      label: "Work load over time",
      color: "rgba(255, 99, 132, 1)",
    },
  ];

  return (
    <>
      <AppHeader onLogout={onLogout} />
      <DrawerNavigation />

      <Paper elevation={1} className="performance-page">
        <Box className="content">
          <Typography variant="h4" gutterBottom>
            Performance
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ height: "100%", backgroundColor: "white" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <Typography variant="h6">No of robots in action</Typography>
                  <Typography variant="h4">
                    {performance.robotsInAction}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ height: "100%", backgroundColor: "white" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <Typography variant="h6">No of tasks running</Typography>
                  <Typography variant="h4">
                    {performance.tasksRunning}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ height: "100%", backgroundColor: "white" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <Typography variant="h6">No of tasks scheduled</Typography>
                  <Typography variant="h4">
                    {performance.tasksScheduled}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card sx={{ height: "100%", backgroundColor: "white" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <Typography variant="h6">
                    Total time spent on tasks today
                  </Typography>
                  <Typography variant="h4">
                    {performance.timeSpentTasksToday}{" "}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="charts">
            <Grid item xs={12} md={6}>
              <Card sx={{ height: "100%", backgroundColor: "white" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <Typography variant="h6">Activity</Typography>
                  <BarChart
                    series={[
                      { data: [3, 4, 1, 6, 5], stack: "A", label: "Series A1" },
                      { data: [4, 3, 1, 5, 8], stack: "A", label: "Series A2" },
                      { data: [4, 2, 5, 4, 1], stack: "B", label: "Series B1" },
                      { data: [2, 8, 1, 3, 1], stack: "B", label: "Series B2" },
                      { data: [10, 6, 5, 8, 9], label: "Series C1" },
                    ]}
                    width={600}
                    height={350}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: "100%", backgroundColor: "white" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                >
                  <Typography variant="h6">Statistics</Typography>
                  <LineChart
                    xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                    series={[
                      {
                        data: [2, 5.5, 2, 8.5, 1.5, 5],
                      },
                    ]}
                    width={500}
                    height={300}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};

export default PerformancePage;
