import { useEffect, useState } from "react";
import templateService from "../services/TemplateService";
import { Template } from "../entities/Task";
import { useIndoorMapContext } from "../context/IndoorMapContext";

export const useTemplates = (): Template[] => {
  const [templates, setTemplates] = useState<Template[]>([]);
  const { selectedFloorId } = useIndoorMapContext();

  useEffect(() => {
    if (selectedFloorId) {
      fetchTemplates();
    }
  }, [selectedFloorId]);

  const fetchTemplates = async () => {
    const initialData = await templateService.getData();
    const filteredTemplates = initialData.filter(
      (template) => template.floor_id === selectedFloorId
    );
    setTemplates(filteredTemplates);
  };

  return templates;
};
