import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProtectedRouteProps } from "../entities/Routing";
import { useAuth } from "../context/AuthContext";
import { useIndoorMapContext } from "../context/IndoorMapContext";
import Loader from "../components/Loader";

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const { isUserLoggedIn, loading, loginError } = useAuth();

  useEffect(() => {
    if (!loading && !isUserLoggedIn) {
      navigate("/");
    }
  }, [isUserLoggedIn, loading, navigate]);

  if (loading) {
    return <Loader />; // Or your loading component
  }

  if (loginError) {
    return <div>Error: {loginError}</div>; // Display error message if there is a login error
  }

  return <>{isUserLoggedIn ? children : null}</>;
};

export default ProtectedRoute;
