import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as THREE from "three";
import { useIndoorMapContext } from "../context/IndoorMapContext";
import { Landmark } from "../entities/Landmark";
import { ObjectItem } from "../entities/Object";
import Scaffold from "../objects/Scaffold";
import { useMappedState } from "../reducers/Store";
import buildingService from "../services/BuildingsService";
import objectService from "../services/ObjectService";
import landmarkService from "../services/landmarkService";
import { getChildByName } from "../utils/Common";
import { CommonThemes } from "../utils/Themes";
import MapLoader from "./MapLoader";

const mapUrl = "/data/map.json";
const raycaster = new THREE.Raycaster();
let raycasterList: any = [];

const useMap = () => {
  const navigate = useNavigate();
  const {
    floors,
    handleSetFloors,
    selectedFloorId,
    setSelectedFloorId,
    handleSelectedFloor,
    handleSelectedMesh,
    setSelectedMesh,
    addLandmark,
    addObject,
  } = useIndoorMapContext();
  const [mall, setMall] = useState<any>();
  const [showAddLandmarkModal, setShowAddLandmarkModal] = useState<any>(false);
  const [showObjectModal, setShowObjectModal] = useState<any>(false);
  const [selectedLandmarkFromDb, setSelectedLandmarkFromDb] =
    useState<Landmark | null>(null);
  const [selectedObjectFromDb, setSelectedObjectFromDb] =
    useState<ObjectItem | null>(null);
  const [previousSelectedMesh, setPreviousSelectedMesh] =
    useState<THREE.Mesh | null>(null);
  const [previousColor, setPreviousColor] = useState<any>(null);
  const [mapId, setMapId] = useState<null | number>(null);
  const { scene: sceneThemes } = CommonThemes;

  const { camera } = useMappedState((state) => ({
    camera: state.camera,
  }));

  const { scene } = useMappedState((state) => ({
    scene: state.scene,
  }));

  const { renderer } = useMappedState((state) => ({
    renderer: state.renderer,
  }));

  const { orbit } = useMappedState((state) => ({
    orbit: state.orbit,
  }));

  const _init = () => {
    console.debug("init called");
    if (scene.userData?.mall) {
      return;
    }
    loadMap(mapUrl);
    resize();
    createScaffold();
    animate();
  };

  const animate = () => {
    requestAnimationFrame(animate);
    orbit && orbit.update();
    renderer.render(scene, camera);
  };

  // Initial Map Loader
  const loadMap = (fileName: string) => {
    console.log("LOADING MAP .....");
    const loader = new MapLoader();
    loader.load(fileName, (mall) => {
      scene.add(mall.root);
      scene.userData.mall = mall;
      mall.showAllFloors();
      raycasterList = getChildByName(mall.root, "floor");
      handleSetFloors(mall.floors);
      setMall(mall);
    });
  };

  const createScaffold = () => {
    const scaffold = new Scaffold();
    scaffold.createLight();
    // scaffold.createAxes();
    scaffold.createOrbit();
  };

  const resize = () => {
    const { width, height } = sceneThemes;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
    renderer.domElement.style.setProperty("width", "100%", "important");
    renderer.domElement.style.setProperty("height", "auto", "important");
  };

  //HANDLING CLICKING ON MESHES
  const onClickFloor = (event: any) => {
    const { container } = sceneThemes;
    if (container) {
      const rect = container.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      const normalizedMouse = normalizeMousePosition(container, x, y);
      raycaster.setFromCamera(normalizedMouse, camera);

      if (raycasterList?.length) {
        const intersects = raycaster.intersectObjects(raycasterList);
        const intersectsExcludingFloor = filterIntersectsByName(
          intersects,
          "floor"
        );

        if (intersectsExcludingFloor?.length) {
          const selectedMesh = getSelectedMesh(intersectsExcludingFloor);
          console.log("seleeeee");
          console.log(selectedMesh);
          if (selectedMesh.name === "floorMesh" && window.location.pathname !== "/layout/tasks") {
            handleSelectedMesh(selectedMesh);
            highlightSelectedMesh(selectedMesh);
            setMapId(selectedMesh?.userData?._id);
            if (window.location.pathname === "/layout/landmarks") {
              handleLandmarkDetails(selectedMesh?.userData?._id);
              setMapId(selectedMesh?.userData?._id);
            } else if (window.location.pathname === "/layout/objects") {
              handleObjectDetails(selectedMesh?.userData?._id);
              setMapId(selectedMesh?.userData?._id);
            }
          }
        }
      }
    }
  };

  // HANDLE LANDMARK:MODAL & FETCHING DATA
  const handleLandmarkDetails = async (mapUnitId: number) => {
    const response = await landmarkService.getDataByMapUnitId(mapUnitId);
    if (response) {
      setSelectedLandmarkFromDb(response);
      setShowAddLandmarkModal(true);
    } else {
      setMapId(mapUnitId);
      setShowAddLandmarkModal(true);
    }
  };

  // HANDLE OBJECT:MODAL & FETCHING DATA
  const handleObjectDetails = async (mapUnitId: number) => {
    const response = await objectService.getDataByMapUnitId(mapUnitId);
    if (response) {
      setSelectedObjectFromDb(response);
      setShowObjectModal(true);
    } else {
      setMapId(mapUnitId);
      setShowObjectModal(true);
    }
  };

  const normalizeMousePosition = (
    container: HTMLElement,
    x: number,
    y: number
  ) => {
    const { clientWidth, clientHeight } = container;
    return new THREE.Vector2(
      (x / clientWidth) * 2 - 1,
      -(y / clientHeight) * 2 + 1
    );
  };

  const filterIntersectsByName = (intersects: Array<any>, name: string) => {
    return intersects.filter((item) => item.object.name !== name);
  };

  const getSelectedMesh = (intersects: Array<any>) => {
    return intersects[0].object as THREE.Mesh;
  };

  //HIGHLIGHTING SELECTED MESH
  const highlightSelectedMesh = (selectedMesh: THREE.Mesh) => {
    if (previousSelectedMesh) {
      removeHighlightOnMesh(previousSelectedMesh);
    }
    const selectedMeshMaterial =
      selectedMesh.material as THREE.MeshPhongMaterial;
    setPreviousColor(selectedMeshMaterial.color.getHex());
    selectedMeshMaterial.color.setRGB(0, 0, 0);
    setPreviousSelectedMesh(selectedMesh);
  };

  //REMOVE HIGHLIGHTING ON MESH
  const removeHighlightOnMesh = (mesh: THREE.Mesh) => {
    const meshMaterial = mesh.material as THREE.MeshPhongMaterial;
    if (previousColor !== null) {
      meshMaterial.color.setHex(previousColor);
    } else {
      meshMaterial.color.setRGB(1, 1, 1);
    }
  };

  // UPDATING FLOOR BY SELECTED ID
  const updateFloorByContext = (selectedFloorId: any) => {
    if (mall) {
      mall?.showFloor(selectedFloorId);
      mall.floors.forEach((floor: any) => {
        if (floor.userData._id !== selectedFloorId) {
          floor.visible = false;
        } else {
          floor.visible = true;

          raycasterList = [];
          floor.children.forEach((child: any) => {
            raycasterList.push(child);
          });
          var bbox = new THREE.Box3().setFromObject(floor);
          var middle = new THREE.Vector3();
          middle.x = (bbox.max.x + bbox.min.x) / 2;
          middle.y = (bbox.max.y + bbox.min.y) / 2;
          middle.z = (bbox.max.z + bbox.min.z) / 2;
          camera.lookAt(middle);
          camera.updateProjectionMatrix();

          if (orbit) {
            orbit.target.set(middle.x, middle.y, middle.z);
            orbit.update();
          }
        }
      });
    }
  };

  // SWITCHING FLOOR BY SELECTED FLOORID
  const onShowFloorById = (floorId: any) => {
    handleSelectedFloor(floorId);
    setSelectedFloorId(Number(floorId));
    if (mall) {
      mall.showFloor(floorId);
      mall.floors.forEach((floor: any) => {
        if (floor.userData._id !== Number(floorId)) {
          floor.visible = false;
        } else {
          floor.visible = true;
          raycasterList = [];
          floor.children.forEach((child: any) => {
            raycasterList.push(child);
          });
        }
      });
    }
  };

  const onShowAllFloors = () => {
    setSelectedFloorId(null);
    loadMap(mapUrl);
  };

  // HANDLING LANDMARK UPDATE&CREATE
  const handleSaveLandmark = async (landmarkData: any) => {
    const { id, name, floor_id, map_unit_id } = landmarkData;
    const buildingId = buildingService.getBuildingIdFromLocalStorage();
    const sanitizedLandmarkData = {
      id: Number(selectedLandmarkFromDb?.id),
      name,
      floor_id: Number(floor_id),
      map_unit_id: Number(map_unit_id),
      building_id: buildingId,
    };
    try {
      if (selectedLandmarkFromDb) {
        await landmarkService.editItem(id, sanitizedLandmarkData);
        navigate("/layout/landmarks");
        if (previousSelectedMesh) {
          removeHighlightOnMesh(previousSelectedMesh);
        }
      } else {
        const data = await landmarkService.addItem(sanitizedLandmarkData);
        addLandmark(data);
      }
      setSelectedFloorId(selectedFloorId);
    } catch (error) {
      console.error("Error editing landmark:", error);
    }
    setSelectedLandmarkFromDb(null);
    setShowAddLandmarkModal(false);
    setSelectedMesh(null);
    if (previousSelectedMesh) {
      removeHighlightOnMesh(previousSelectedMesh);
    }
  };

  // HANDLING LANDMARK MODAL
  function handleLandmarkModalClose() {
    setSelectedLandmarkFromDb(null);
    setSelectedMesh(null);
    setShowAddLandmarkModal(false);
    if (previousSelectedMesh) {
      removeHighlightOnMesh(previousSelectedMesh);
    }
  }

  // HANDLING OBJECT MODAL
  function handleObjectModalClose() {
    setShowObjectModal(false);
    setSelectedMesh(null);
    setSelectedObjectFromDb(null);
    if (previousSelectedMesh) {
      removeHighlightOnMesh(previousSelectedMesh);
    }
  }

  // HANDLING OBJECT UPDATE&CREATE
  const handleSaveObject = async (objectData: any) => {
    const { name, tag, building_id } = objectData;
    const sanitizedObjectData: ObjectItem = {
      id: Number(selectedObjectFromDb?.id),
      tag: tag,
      name: name,
      map_unit_id: mapId,
      floor_id: selectedFloorId,
      building_id: building_id,
    };
    try {
      if (selectedObjectFromDb) {
        await objectService.editItem(
          Number(selectedObjectFromDb?.id),
          sanitizedObjectData
        );
        navigate("/layout/objects");
        if (previousSelectedMesh) {
          removeHighlightOnMesh(previousSelectedMesh);
        }
      } else {
        const data = await objectService.addItem(sanitizedObjectData);
        addObject(data);
      }
    } catch (error) {
      console.error("Error editing landmark:", error);
    }
    setSelectedObjectFromDb(null);
    setShowObjectModal(false);
    setSelectedMesh(null);
    if (previousSelectedMesh) {
      removeHighlightOnMesh(previousSelectedMesh);
    }
  };

  return {
    _init,
    updateFloorByContext,
    onClickFloor,
    renderer,
    sceneThemes,
    floors,
    showAddLandmarkModal,
    showObjectModal,
    selectedLandmarkFromDb,
    handleLandmarkModalClose,
    handleObjectModalClose,
    onShowFloorById,
    onShowAllFloors,
    handleSaveLandmark,
    mall,
    mapId,
    highlightSelectedMesh,
    raycasterList,
    handleSaveObject,
    selectedObjectFromDb,
  };
};

export default useMap;
