import { Box, Button } from "@mui/material";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import taskService from "../../../services/TaskService";

const TriggerTask = () => {
  const navigate = useNavigate();
  const { setValue, watch, getValues } = useFormContext();
  const data = getValues();
  const [openCalendar, setOpenCalendar] = useState(true);

  return (
    <>
      {watch("type") === "schedule" ? (
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              open={openCalendar}
              label="Schedule Time"
              onChange={(date) => {
                if (dayjs(date).isValid()) {
                  setValue("schedule_time", date.toDate());
                }
              }}
              onClose={() => setOpenCalendar(false)}
              sx={{ width: "100%", height: "100%" }}
            />
          </LocalizationProvider>
          <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
            <Button
              fullWidth
              variant="text"
              color="primary"
              onClick={() => {
                setValue("type", "");
                taskService.testApi();
              }}
              sx={{ mr: 1 }}
              disableElevation
            >
              Cancel
            </Button>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              disableElevation
            >
              Save
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center", my: 1 }}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => {
              setValue("type", "schedule");
            }}
            sx={{ mr: 1 }}
            disableElevation
          >
            Schedule For Later
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={() => {
              setValue("type", "run_now");
              setValue("schedule_time", new Date());
            }}
            disableElevation
          >
            Run Now
          </Button>
        </Box>
      )}
    </>
  );
};

export default TriggerTask;
