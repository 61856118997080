import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "./theme";
import { LoaderProvider } from "../src/context/LoaderContext";
import { BrowserRouter } from "react-router-dom";
import { StoreContext, makeStore } from "./reducers/Store";
import "./Style/normalized.css";
import { IndoorMapProvider } from "./context/IndoorMapContext";
import { AuthProvider } from "./context/AuthContext";

export const store = makeStore();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <CssBaseline />
      <LoaderProvider>
        <AuthProvider>
          <StoreContext.Provider value={store}>
            <IndoorMapProvider>
              <App />
            </IndoorMapProvider>
          </StoreContext.Provider>
        </AuthProvider>
      </LoaderProvider>
    </BrowserRouter>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
