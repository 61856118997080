import User from "../entities/User";

class AuthService {
  private data: User[] = [];

  constructor() {
    this.loadData().then((data) => {
      this.data = data;
    });
  }
  async loadData(): Promise<User[]> {
    try {
      const response = await fetch("/data/users.json");
      this.data = await response.json();
      return this.data;
    } catch (error) {
      console.error("Error loading data:", error);
      return [];
    }
  }

  async authenticateUser(user: {
    email: string;
    password: string;
  }): Promise<User | null> {
    try {
      const authenticatedUser = this.data.find(
        (u: User) => u.email === user.email && u.password === user.password
      );

      return authenticatedUser || null;
    } catch (error) {
      console.error("Error authenticating user:", error);
      return null;
    }
  }


  async fetchUserByEmail(user: {
    email: string;
  }): Promise<User | null> {
    try {
      const authenticatedUser = this.data.find(
        (u: User) => u.email === user.email
      );

      return authenticatedUser || null;
    } catch (error) {
      console.error("Error finding user:", error);
      return null;
    }
  }
  
  getData(): User[] {
    return this.data;
  }

  addItem(item: User): void {
    this.data.push(item);
  }

  deleteItem(id: number): void {
    this.data = this.data.filter((item) => item.id !== id);
  }

  editItem(id: number, updatedItem: Partial<User>): void {
    this.data = this.data.map((item) =>
      item.id === id ? { ...item, ...updatedItem } : item
    );
  }
}

const authService = new AuthService();
export default authService;
