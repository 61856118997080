import { Step, Template } from "../entities/Task";
import { getCurrentUser } from "../utils/generic-functions";
import buildingService from "./BuildingsService";
import landmarkService from "./landmarkService";

class TemplateService {
  private data: Template[] = [];

  constructor() {
    this.loadData().then((data) => {
      this.data = data;
    });
  }

  async loadData(): Promise<Template[]> {
    const buildingId = buildingService.getBuildingIdFromLocalStorage();

    try {
      const response = await fetch("/data/templates.json");
      this.data = await response.json();
      if (buildingId) {
        this.data = this.data.filter(
          (item: Template) => item.building_id === buildingId
        );
      }
      console.log("🚀 ~ TemplateService ~ loadData ~ this.data :", this.data);

      return this.data;
    } catch (error) {
      console.error("Error loading data:", error);
      return [];
    }
  }

  async saveData(): Promise<void> {
    try {
      const response = await fetch("/data/saveTemplate.json", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.data),
      });
      if (!response.ok) {
        throw new Error("Failed to save data");
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  }

  getLength() {
    return this.data.length;
  }

  async getData(): Promise<Template[]> {
    if (!this.data || !this.data.length) {
      return this.loadData();
    } else {
      return Promise.resolve(this.data);
    }
  }

  minutesToSeconds(min: number) {
    return min * 60
  }

  updateTemplateSteps(steps: any): Step[] {
    const updatedSteps = []
    for (let i = 0; i < steps.length; i++) {
      let obj: Step = {
        id: Math.floor(Math.random() * 900) + 100,
        type: String(steps[i].type).toLowerCase(),
        step_landmark: steps[i].step_landmark.length > 0 ? Number(steps[i].step_landmark[1]) : "",
        step_wait_time: steps[i].step_wait_time.lengt > 0 ? String(this.minutesToSeconds(Number(steps[i].step_wait_time[1]))) : ""
      }
      updatedSteps.push(obj)
    }
    return updatedSteps
  }

  addItem(item: any, floorId: number, buildingId: number) {
    const templateSteps = this.updateTemplateSteps(item.steps)
    const newTemplateObj: Template = {
      id: this.data.length + 1,
      name: item.name,
      created_user_id: getCurrentUser().id,
      executed_count: 0,
      floor_id: floorId,
      building_id: buildingId,
      steps: templateSteps
    }
    this.data.push(newTemplateObj);
    return newTemplateObj
  }

  deleteItem(id: number): void {
    this.data = this.data.filter((item) => item.id !== id);
  }

  editItem(id: number, updatedItem: Partial<Template>): void {
    this.data = this.data.map((item) =>
      item.id === id ? { ...item, ...updatedItem } : item
    );
  }

  getTaskNameByTemplateId(id: number): string {
    console.log(
      "🚀 ~ TemplateService ~ getTaskNameByTemplateId ~ this.data:",
      this.data
    );

    try {
      const template = this.data.find((item) => item.id === id);
      console.log(
        "🚀 ~ TemplateService ~ getTaskNameByTemplateId ~ template:",
        template
      );
      if (template) {
        return template.name;
      } else {
        return "";
        // throw new Error(`Template with id ${id} not found`);
      }
    } catch (error) {
      console.log("Error while finding name by templateId", error);
      throw new Error("Failed to get task name");
    }
  }

  getTemplateById(id: number): Template {
    try {
      const template = this.data.find((item) => item.id === id);
      if (template) {
        return template;
      } else {
        throw new Error(`Template with id ${id} not found`);
      }
    } catch (error) {
      console.log("Error while finding template by templateId", error);
      throw new Error("Failed to get template");
    }
  }

  getStepLandmarkByTemplateIdAndStepId(templateId: number, stepId: number) {
    try {
      const template = this.data.find((item) => item.id === templateId);
      if (template) {
        console.log(template.steps[0].id, "exist iddd");
        const step = template.steps.find((item) => item.id === Number(stepId));
        if (step) {
          console.log("step is", step);
          return step.step_landmark;
        } else {
          throw new Error(`Step with id ${stepId} not found`);
        }
      } else {
        throw new Error(`Template with id ${templateId} not found`);
      }
    } catch (error) {
      console.log("Error while finding step by templateId", error);
      throw new Error("Failed to get steps");
    }
  }

  checkExistingTemplate(id: number): boolean {
    try {
      const template = this.data.find((item) => item.id === id);
      return template ? true : false;
    } catch (error) {
      console.log("Error while finding template by templateId", error);
      throw new Error("Failed to get template");
    }
  }

  async incrementExecutionCount(id: number): Promise<void> {
    this.data = this.data.map((item) =>
      item.id === id
        ? { ...item, executed_count: item.executed_count + 1 }
        : item
    );
    await this.saveData();
  }

  addApiResponseToTemplate(data:Template){
    this.data.push(data)
  }
}

const templateService = new TemplateService();
export default templateService;

// interface TemplateServiceProps {
//   getTemplateById: (templateId: number) => Promise<void>;
//   getAllTemplates: () => Promise<any>;
//   saveTemplate: (data: any) => void;
// }

// const TemplateService: TemplateServiceProps = {
//   getTemplateById: async (templateId: number) => {
//     try {
//       const res = fetch("/data/templates.json");
//       //return res;
//     } catch (error) {
//       console.error(error);
//       throw error;
//     }
//   },

//   getAllTemplates: async () => {
//     try {
//       const savedTemplates = localStorage.getItem("savedTemplates");
//       const templates = savedTemplates ? JSON.parse(savedTemplates) : [];
//       return templates;
//     } catch (error) {
//       console.error(error);
//       throw error;
//     }
//   },

//   saveTemplate: (data: any) => {
//     const savedTemplates = localStorage.getItem("savedTemplates");
//     let templates = savedTemplates ? JSON.parse(savedTemplates) : [];
//     templates.push(data);
//     localStorage.setItem("savedTemplates", JSON.stringify(templates));
//   },
// };

// export default TemplateService;
