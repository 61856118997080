import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  styled,
} from "@mui/material";
import PageTitle from "../PageTitle";
import Autocomplete from "@mui/material/Autocomplete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BuildingsService from "../../services/BuildingsService";
import { generateRandomId } from "../../utils/generic-functions";
import "./index.scss";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 2,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddBuildingModal = ({
  addBuildingModal,
  handleCloseBuildingModal,
  storeAdminNames,
  setAdminName,
  adminName,
  setBuildingName,
  buildingName,
  handleSaveBuildingModal,
}: any) => {
  return (
    <>
      <Modal
        open={addBuildingModal}
        onClose={handleCloseBuildingModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PageTitle title="ADD BUILDING" />
          </Box>
          <Box>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Enter Building Name"
              variant="outlined"
              size="small"
              value={buildingName}
              onChange={(e) => setBuildingName(e.target.value)}
              sx={{ marginTop: "20px", marginBottom: "10px" }}
            />
            <Autocomplete
              fullWidth
              disablePortal
              id="combo-box-demo"
              options={storeAdminNames}
              sx={{ marginBottom: "10px" }}
              value={adminName}
              onChange={(e, value) => setAdminName(value as string)}
              renderInput={(params) => (
                <TextField {...params} label="Select Admin" size="small"
                />
              )}
            />
            <Button
              fullWidth
              size="small"
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              sx={{ marginBottom: "20px" }}
              startIcon={<CloudUploadIcon />}
              className="outlined-button"
            >
              Upload file
              <VisuallyHiddenInput type="file" />
            </Button>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              fullWidth
              size="small"
              className="outlined-button"
              variant="outlined"
              onClick={handleCloseBuildingModal}
            >
              Cancel
            </Button>
            <Button
              fullWidth  size="small"

              className="contained-button"
              sx={{ ml: 1 }}
              variant="contained"
              onClick={handleSaveBuildingModal}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AddBuildingModal;
