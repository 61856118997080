import React from "react";
import { Template } from "../../../entities/Task";
import { Box } from "@mui/system";
import { Avatar, Typography } from "@mui/material";
import tempUserService from "../../../services/TempUserService";

const TemplateStats = ({ template }: any) => {
  return (
    <Box className="heading-box">
      <Box className="task-content">
        <Typography variant="subtitle1">Execution Count</Typography>
        <Box className="task-card-info">
          <Typography variant="h3" sx={{ ml: 1 }}>
            {" "}
            {template?.executed_count}
          </Typography>
        </Box>
      </Box>

      <Box className="task-content">
        <Typography variant="subtitle1">Steps</Typography>
        <Box className="task-card-info">
          <img
            src="/assets/footprint.png"
            width={"15"}
            height={"17"}
            alt="footprint"
          />
          <Typography variant="h3" sx={{ ml: 1 }}>
            {template?.steps?.length}
          </Typography>
        </Box>
      </Box>

      <Box className="task-content">
        <Typography variant="subtitle1">Created by</Typography>
        <Box className="task-card-info">
          <Avatar
            className="avatar"
            src="https://i.pravatar.cc/30?u=runTask.robotId"
          ></Avatar>
          <Typography variant="h3" sx={{ ml: 1 }}>
            {tempUserService.getUserNameByUserId(template?.created_user_id)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TemplateStats;
