import { Box, Typography } from "@mui/material";
import templateService from "../../../services/TemplateService";
import { convertToStandardTimeFormat } from "../../../utils/generic-functions";

const TaskHeader = ({ task }: any) => {
  const taskName = templateService.getTaskNameByTemplateId(task?.template_id);
  return (
    <Box className="heading-box">
      <Box className="title-box">
        <Typography variant="h2" color={"theme.palette.text.primary"}>
          {taskName}
        </Typography>
      </Box>
      <Typography variant="subtitle2" color={"theme.palette.text.primary"}>
        {task?.start_time
          ? convertToStandardTimeFormat(task?.start_time)
          : "Yet to start"}{" "}
        {task?.end_time
          ? " - " + convertToStandardTimeFormat(task?.end_time)
          : ""}
      </Typography>
    </Box>
  );
};

export default TaskHeader;
