import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import CreateTemplate from "../CreateTemplate";
import SelectTemplate from "../SelectTemplate";
import TriggerTask from "../TriggerTask";
import { useIndoorMapContext } from "../../../context/IndoorMapContext";
import taskService from "../../../services/TaskService";
import templateService from "../../../services/TemplateService";

const CreateTask = () => {
  const { selectedFloorId, filled } = useIndoorMapContext();
  const methods = useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("🚀 ~ CreateTask ~ state:", state);
  const buildinId = localStorage.getItem("building_id");

  const templateId = useWatch({
    control: methods.control,
    name: "template_id",
    defaultValue: 0,
  });

  const onSubmit = async (data) => {
    console.log("🚀 ~ onSubmit ~ data:", data);
    if (data.template_id) {
      handleRunTask(data.template_id, data);
      navigate("/layout/tasks");
    } else {
      console.log("data....", data);
      try {
        await taskService.addDataApi(data, selectedFloorId, Number(buildinId));
        navigate("/layout/tasks");
      } catch (error) {
        console.log(error, "onsubmit add task");
      }
      // const template = handleTemplateCreation(data);
      // const requestOptions = {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(data),
      // };

      // try {
      //   const url = "https://api.nandi.autos/create/task";
      //   const response = await fetch(url, requestOptions);
      //   console.log(response, "_____");
      //   if (!response.ok) {
      //     throw new Error("Network response was not ok");
      //   }
      //   const responseData = await response.json();
      //   console.log(responseData, "__________");
      //   if (responseData) {
      //     handleRunTask(responseData.id, data);
      //     navigate("/layout/tasks");
      //   }
      //   console.log("Task created successfully:", responseData);
      // } catch (error) {
      //   console.error("Error creating task:", error);
      // }
    }
    const transformedData = transformResponse(data);
    // taskService.addItem(transformedData, selectedFloorId);
    //navigate("/layout/tasks");
  };

  function handleTemplateCreation(data: any) {
    data.floorId = selectedFloorId;
    const template = templateService.addItem(
      data,
      selectedFloorId,
      Number(buildinId)
    );
    return template;
  }

  function handleRunTask(templateId: number, data: any) {
    taskService.runTask(templateId, data);
  }

  const transformResponse = (data) => {
    return {
      ...data,
      steps: data.steps.map((step) => ({
        type: step.type,
        step_landmark:
          step.step_landmark.length > 1 ? step.step_landmark[1] : "",
        step_wait_time:
          step.step_wait_time.length > 1 ? step.step_wait_time[1] : "",
      })),
    };
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <SelectTemplate />
        {templateId === 0 && <CreateTemplate />}
        {templateId > 0 || filled ? <TriggerTask /> : null}
      </form>
    </FormProvider>
  );
};

export default CreateTask;
