import { Landmark } from "../entities/Landmark";
import { ObjectItem } from "../entities/Object";
import { Robot } from "../entities/Robot";
import User from "../entities/User";
interface RobotsByFloor {
  [key: number]: Robot[];
}

export function formatAMPM(date: Date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = Number(minutes < 10 ? "0" + minutes : minutes);
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function loadScript(src: any) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.defer = true;
    script.type = "text/javascript";
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}
export function isValidUser(user: User) {
  return user?.active && user?.role !== null;
}

export function getCurrentUser(): any {
  const user = localStorage.getItem("current-user");
  return user ? JSON.parse(user) : null;
}

export const convertToStandardTimeFormat = (dateTimeString: string): string => {
  const date = new Date(dateTimeString);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedTime = `${hours}:${minutes < 10 ? "0" + minutes : minutes
    }${ampm}`;
  return formattedTime;
};

export function generateRandomId(length: number): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = length; i > 0; --i) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function groupedLandmarksByFloorId(landmarks: Landmark[]) {
  return landmarks.reduce(
    (acc: Record<number, Landmark[]>, landmark: Landmark) => {
      const { floor_id } = landmark;
      if (!acc[floor_id]) {
        acc[floor_id] = [];
      }
      acc[floor_id].push(landmark);
      return acc;
    },
    {} as { [key: number]: Landmark[] }
  );
}

export function groupedObjectsByFloorId(objects: ObjectItem[]) {
  return objects.reduce((acc: Record<number, any[]>, object: ObjectItem) => {
    const { floor_id } = object;
    if (!acc[floor_id]) {
      acc[floor_id] = [];
    }
    acc[floor_id].push(object);
    return acc;
  }, {} as { [key: number]: any[] });
}

export function groupRobotsByFloorId(robots: Robot[]): RobotsByFloor {
  return robots.reduce((acc: Record<number, Robot[]>, robot: Robot) => {
    const { floor_id } = robot;
    if (!acc[floor_id]) {
      acc[floor_id] = [];
    }
    acc[floor_id].push(robot);
    return acc;
  }, {} as RobotsByFloor);
}

export function generateTimeIntervals(minutes: number) {
  const timeIntervals = [];
  for (let i = 5; i <= minutes; i += 5) {
    timeIntervals.push({ label: `${i} mins`, value: `${i}` });
  }
  return timeIntervals;
}


export const formatDate = () => {
  const date = new Date();
  return `${date.toISOString().slice(0, 10)} ${date.toTimeString().slice(0, 8)}`;
};

export function DateFromIso(isoString: any): string {
  const date = new Date(isoString);
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-US', options).replace(',', '');
}
