import { Avatar, Box, Chip, Typography } from "@mui/material";
import React from "react";
import tempUserService from "../../../services/TempUserService";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const TaskStats = ({ task }: any) => {
  const statusMap: any = {
    completed: (
      <Chip
        sx={{ height: "100%", borderRadius: "8px" }}
        color="success"
        label="Completed"
      />
    ),
    running: (
      <Chip
        sx={{ height: "100%", borderRadius: "8px" }}
        color="warning"
        label="Running"
      />
    ),
    failed: (
      <Chip
        sx={{ height: "100%", borderRadius: "8px" }}
        color="error"
        label="Failed"
      />
    ),
    pending: (
      <Chip
        sx={{ height: "100%", borderRadius: "8px" }}
        color="default"
        label="Pending"
      />
    ),
  };

  return (
    <Box className="heading-box">
      <Box className="task-content">
        <Typography variant="subtitle1">Robot ID</Typography>
        <Box className="task-card-info">
          <img
            src="/assets/smart_toy.png"
            width={"16"}
            height={"14"}
            alt="smart_toy"
          />
          <Typography variant="h3" sx={{ ml: "4px" }}>
            {task?.robot_id}
          </Typography>
        </Box>
      </Box>
      <Box className="task-content">
        <Typography variant="subtitle1">Steps</Typography>
        <Box className="task-card-info">
          <img
            src="/assets/footprint.png"
            width={"15"}
            height={"17"}
            alt="footprint"
          />
          <Typography variant="h3">{task?.steps.length}</Typography>
        </Box>
      </Box>
      <Box className="task-content">
        <Typography variant="subtitle1">Executed by</Typography>
        <Box className="task-card-info">
          <Avatar
            className="avatar"
            src="https://i.pravatar.cc/30?u=task.robotId"
          ></Avatar>
          <Typography variant="h3" sx={{ ml: "4px" }}>
            {tempUserService.getUserNameByUserId(task?.initiated_user_id)}
          </Typography>
        </Box>
      </Box>
      <Box className="task-content">
        <Typography variant="subtitle1">Status</Typography>
        <Box display={"flex"} className="status-icon">
          {statusMap[task?.status] || statusMap.pending}
        </Box>
      </Box>
    </Box>
  );
};

export default TaskStats;
