import { useEffect, useState } from "react";
import objectService from "../services/ObjectService";
import { ObjectItem } from "../entities/Object";
import { useIndoorMapContext } from "../context/IndoorMapContext";
import buildingService from "../services/BuildingsService";

export const useObjects = (): ObjectItem[] => {
  const { objects, setObjects } = useIndoorMapContext();
  const buildingId = buildingService.getBuildingIdFromLocalStorage();

  useEffect(() => {
    fetchObjects();
  }, []);

  const fetchObjects = async () => {
    const data = await objectService.getData();
    setObjects(data);
  };
  return objects;
};
