import { Box, Divider, Grid, Toolbar } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view";
import PageTitle from "../../components/PageTitle";
import { useIndoorMapContext } from "../../context/IndoorMapContext";
import { useObjects } from "../../hooks/useObjects";
import useMap from "../../map/useMap";
import { CustomTreeItem } from "../../utils/Themes";
import { groupedObjectsByFloorId } from "../../utils/generic-functions";
import { ObjectItem } from "../../entities/Object";
import { useEffect, useState } from "react";
import MapIcons from "../../map/MapIcons";

const Objects = () => {
  const objects = useObjects();
  const [groupedObjects, setGroupedObjects] = useState<
    Record<string, ObjectItem[]>
  >({});

  const {
    showObjectIcon,
    findMeshbyMapUnitId,
    getMeshesByMapUnitIds,
    removeObjectIcons,
  } = MapIcons();
  const { onShowFloorById, highlightSelectedMesh } = useMap();
  const { setSelectedMesh, selectedFloorId } = useIndoorMapContext();

  useEffect(() => {
    const getGroupedObjects = () => {
      const groupedObjects = groupedObjectsByFloorId(objects);
      setGroupedObjects(groupedObjects);
    };
    getGroupedObjects();
  }, [objects]);

  const handleClickOnObject = (object: ObjectItem) => {
    const mesh = findMeshbyMapUnitId(object.map_unit_id);
    if (mesh) {
      setSelectedMesh(mesh);
      highlightSelectedMesh(mesh);
    }
  };

  const getMapIds = (floorId: number) => {
    const data: ObjectItem[] = objects.filter(
      (item) => item.floor_id === floorId
    );
    const mapIds: number[] = data.map((item) => item.map_unit_id);
    return mapIds;
  };

  const displayObjectIcons = (floorId: number) => {
    const ids = getMapIds(floorId);
    const meshes = getMeshesByMapUnitIds(ids);
    showObjectIcon(meshes);
  };

  const deleteIcon = (floorId: number) => {
    const ids = getMapIds(floorId);
    const meshes = getMeshesByMapUnitIds(ids);
    removeObjectIcons(meshes);
  };

  const handleClick = (floorId: number) => {
    onShowFloorById(floorId);
    displayObjectIcons(floorId);
  };

  useEffect(() => {
    if (window.location.pathname === "/layout/objects") {
      displayObjectIcons(selectedFloorId);
    }

    return () => {
      if (window.location.pathname !== "/layout/objects") {
        deleteIcon(selectedFloorId);
      }
    };
  }, [selectedFloorId, window.location.pathname, objects.length]);

  return (
    <Box component="div" sx={{ minWidth: "400px" }}>
      <Toolbar
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "8px !important",
          paddingRight: "8px !important",
        }}
      >
        <PageTitle title="OBJECTS" />
      </Toolbar>
      <Divider />
      <Box
        sx={{
          overflowY: "auto",
          height: "calc(100vh - 168px)",
          overflowX: "hidden",
        }}
      >
        <Grid sx={{ p: 0.5 }}>
          <SimpleTreeView>
            {Object.entries(groupedObjects).map(([floorId, objects]) => (
              <CustomTreeItem
                key={floorId}
                itemId={`floor-${floorId}`}
                label={`Floor ${floorId}`}
                onClick={() => handleClick(Number(floorId))}
              >
                {objects?.map((object, index) => (
                  <CustomTreeItem
                    key={object.id}
                    itemId={`object-${index}`}
                    label={object.name}
                    onClick={() => handleClickOnObject(object)}
                  />
                ))}
              </CustomTreeItem>
            ))}
          </SimpleTreeView>
        </Grid>
      </Box>
    </Box>
  );
};

export default Objects;
