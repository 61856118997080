import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
} from "@mui/material";
import TemplateHeader from "./TemplateHeader";
import "./index.scss";
import TemplateStats from "./TemplateStats";
import TemplateDetails from "./TemplateDetails";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../utils/generic-functions";
const TemplateCard = ({
  template,
  expanded,
  handleChange,
}: {
  template: any;
  expanded: number;
  handleChange: any;
}) => {
  const templateId = template?.id;
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate("/layout/add-tasks", { state: { templateId } });
  };
  return (
    <Box sx={{ px: 1, py: 0.5 }}>
      <Accordion
        onChange={handleChange(template?.id)}
        sx={{ backgroundColor: "white" }}
        expanded={expanded === templateId}
      >
        <AccordionSummary
          className="task-card-container"
          expandIcon={<></>}
          aria-controls={`${template?.id}-content`}
          id={`${template.id}-header`}
        >
          <Box className="card-content">
            <TemplateHeader template={template} />
            <TemplateStats template={template} />
          </Box>
        </AccordionSummary>
        <AccordionDetails id={`${template?.id}-content`}>
          <TemplateDetails template={template} />
        </AccordionDetails>
        {getCurrentUser().role === "staff" && (
          <AccordionActions>
            <Button onClick={navigateTo}>Use Template</Button>
          </AccordionActions>
        )}
      </Accordion>
    </Box>
  );
};

export default TemplateCard;
