import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Loader from "../components/Loader";

const ProtectedLogin: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isUserLoggedIn, loading, loginError, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && isUserLoggedIn) {
      navigate(
        currentUser?.role === "super-admin" || currentUser?.role === "admin"
          ? "/buildings"
          : "/layout/tasks"
      );
    }
  }, [isUserLoggedIn, loading, navigate, currentUser]);

  if (loading) {

    return <Loader />; 
 }

  // if (loginError) {
  //   return <div>Error: {loginError}</div>;
  // }

  return <>{!isUserLoggedIn ? children : null}</>;
};

export default ProtectedLogin;
