import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  Alert,
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Breadcrumbs,
  Stack,
  Link,
  Typography,
} from "@mui/material";
import { getCurrentUser } from "../../utils/generic-functions";
import "./index.scss";
import { useEffect, useState } from "react";
import buildingService from "../../services/BuildingsService";
import { useBuildings } from "../../hooks/useBuildings";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";

const AppHeader = ({ onLogout, props }: any) => {
  const currentUser = getCurrentUser();
  console.log("🚀 ~ AppHeader ~ currentUser:", currentUser);
  const [buildingName, setBuildingName] = useState<string>("");
  const navigate = useNavigate();

  const breadcrumbs = [
    currentUser.role === "super-admin" && (
      <Link
        underline="hover"
        key="2"
        color="inherit"
        href="/buildings"
        onClick={(event) => handleClick(event, "/buildings")}
      >
        All buildings
      </Link>
    ),
    currentUser.role === "super-admin" && buildingName && (
      <Typography key="3" color="text.primary">
        {buildingName}
      </Typography>
    ),
  ].filter(Boolean);

  useEffect(() => {
    if (window.location.pathname === "/buildings") return;
    const urlParams = new URLSearchParams(window.location.search);
    const idParam = urlParams.get("id");
    console.log("🚀 ~ useEffect ~ idParam:", idParam);
    if (idParam) {
      fetchBuildingName(parseInt(idParam));
      return;
    } else {
      fetchBuildingName(currentUser?.buildings[0]);
    }
  }, [currentUser]);

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string
  ) => {
    event.preventDefault();
    navigate(path);
  };

  const fetchBuildingName = async (id: number) => {
    console.log("🚀 ~ fetchBuildingName ~ id:", id);
    try {
      const building = await buildingService.getBuildingNameById(id);
      console.log("🚀 ~ fetchBuildingName ~ building:", building);
      if (building) {
        setBuildingName(building);
      }
    } catch (error) {
      console.error("Error while fetching building name:", error);
    }
  };

  return (
    <AppBar sx={{ boxShadow: "none", zIndex: 1206 }}>
      <Toolbar className="header-container">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src="/assets/nandi_logo.png"
            alt="logo"
            width="auto"
            height={"32px"}
            style={{ marginRight: "20px" }}
          />
          {currentUser.role !== "super-admin" && buildingName ? (
            <Alert severity="success">{buildingName}</Alert>
          ) : (
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Stack>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            aria-label="logout"
            size="small"
            className="header-icons"
            onClick={onLogout}
          >
            <LogoutOutlinedIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
