import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import "./index.scss"
const Loader = () => {
  return (
    <Box className="vertical_center">
      <CircularProgress />
    </Box>
  );
};

export default Loader;
