import { Box, Button, Toolbar, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddBuildingModal from "../../components/AddBuildingModal";
import AppHeader from "../../components/AppHeader";
import BuildingCard from "../../components/BuildingCard";
import PageTitle from "../../components/PageTitle";
import { Building } from "../../entities/Building";
import { useBuildings } from "../../hooks/useBuildings";
import { getCurrentUser } from "../../utils/generic-functions";
import "./index.scss";

const Buildings = ({ onLogout }: any) => {
  const buildings = useBuildings();
  const [addBuildingModal, setAddBuildingModal] = useState(false);
  const [storeAdminNames, setStoreAdminNames] = useState([]);
  const [buildingName, setBuildingName] = useState("");
  const [adminName, setAdminName] = useState("");
  const currentUser = getCurrentUser();

  const handleAddBuildingModal = () => {
    setAddBuildingModal(true);
  };

  const handleCloseBuildingModal = () => {
    setAddBuildingModal(false);
  };

  const handleSaveBuildingModal = () => {
    const randomId = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    const data = {
      id: randomId,
      floorplan_url: "https://picsum.photos",
      name: buildingName,
      info1: "108",
      info2: "128",
      info3: "04",
      info4: "274",
      floors: [
        {
          id: 1,
          floor_name: "Ground Floor",
          landmarks: [
            { landmark_name: "Security room", landmark_id: 1 },
            { landmark_name: "OT", landmark_id: 2 },
          ],
        },
        {
          id: 2,
          floor_name: "First Floor",
          landmarks: [
            { landmark_name: "Security room", landmark_id: 1 },
            { landmark_name: "Warehouse", landmark_id: 2 },
          ],
        },
        {
          id: 3,
          floor_name: "Second Floor",
          landmarks: [
            { landmark_name: "Security room", landmark_id: 1 },
            { landmark_name: "Warehouse", landmark_id: 2 },
          ],
        },
      ],
    };
    const buildingData = JSON.stringify(data);
    // BuildingsService.storeBuildingData(buildingData);
    handleCloseBuildingModal();
  };

  return (
    <>
      <AppHeader onLogout={onLogout} />
      <Box className="buildings-container">
        <Toolbar className="buildings-header">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PageTitle title="Buildings" />
            <Typography sx={{ ml: 1 }} color="text.secondary">
              ({buildings.length})
            </Typography>
          </Box>
          {currentUser?.role === "super-admin" && (
            <Button
              className="contained-button"
              variant="contained"
              onClick={handleAddBuildingModal}
            >
              Add Building
            </Button>
          )}
        </Toolbar>
        <Box className="buildings-list">
          {buildings.map((building: Building) => (
            <BuildingCard key={building.id} building={building} />
          ))}
        </Box>
        <AddBuildingModal
          addBuildingModal={addBuildingModal}
          storeAdminNames={storeAdminNames}
          handleCloseBuildingModal={handleCloseBuildingModal}
          setBuildingName={setBuildingName}
          buildingName={buildingName}
          setAdminName={setAdminName}
          adminName={adminName}
          handleSaveBuildingModal={handleSaveBuildingModal}
        />
      </Box>
    </>
  );
};

export default Buildings;
