import { useEffect, useState } from "react";
import { Building } from "../entities/Building";
import buildingService from "../services/BuildingsService";

export const useBuildings = () => {
  const [buildings, setBuildings] = useState<Building[]>([]);

  useEffect(() => {
    const fetchBuildings = async () => {
      const initialData = await buildingService.getData();
      setBuildings(initialData);
    };
    fetchBuildings();
  }, []);

  return buildings;
};
