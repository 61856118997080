import React, { useState } from "react";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Tooltip from "@mui/material/Tooltip";
import robotService from "../../services/RobotService";

dayjs.extend(utc);

interface FilterMenuProps {
  setSearchQuery: (query: string) => void;
  searchQuery: string;
  setStatus: (status: string) => void;
  selectedStartDate: dayjs.Dayjs | null;
  selectedEndDate: dayjs.Dayjs | null;
  setSelectedStartDate: (date: dayjs.Dayjs | null) => void;
  setSelectedEndDate: (date: dayjs.Dayjs | null) => void;
  status: string;
  robots: any;
  setRobotFilter: (robot: string) => void;
  robotFilter: string;
}

const FilterMenu: React.FC<FilterMenuProps> = ({
  searchQuery,
  setSearchQuery,
  selectedStartDate,
  selectedEndDate,
  setStatus,
  setSelectedStartDate,
  setSelectedEndDate,
  status,
  robots,
  setRobotFilter,
  robotFilter,
}) => {
  const [robotAnchorEl, setRobotAnchorEl] = useState<null | HTMLElement>(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    setStatusAnchorEl(event.currentTarget);
    setDateAnchorEl(null);
    setRobotAnchorEl(null);
  };
  const [dateAnchorEl, setDateAnchorEl] = useState<null | HTMLElement>(null);

  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);

  const handleApply = () => {
    setSelectedStartDate(tempStartDate);
    setSelectedEndDate(tempEndDate);
    handleFilterClose();
  };

  const handleDateClick = (event: React.MouseEvent<HTMLElement>) => {
    setDateAnchorEl(event.currentTarget);
    setStatusAnchorEl(null);
    setRobotAnchorEl(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStatusFilter = (selectedStatus: string) => {
    setStatus(selectedStatus);
    setAnchorEl(null);
    setStatusAnchorEl(null);
    setDateAnchorEl(null);
  };

  const handleRobotClick = (event: React.MouseEvent<HTMLElement>) => {
    setRobotAnchorEl(event.currentTarget);
    setDateAnchorEl(null);
    setStatusAnchorEl(null);
  };

  const handleRobotFilter = (selectedRobot: string) => {
    setRobotFilter(selectedRobot);
    setRobotAnchorEl(null);
    setAnchorEl(null);
  };

  const handleClearFilters = () => {
    setStatus("");
    setAnchorEl(null);
    setSearchQuery("");
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setStatusAnchorEl(null);
    setDateAnchorEl(null);
    setRobotFilter("");
  };

  const handleFilterClose = () => {
    setDateAnchorEl(null);
    setAnchorEl(null);
    setRobotAnchorEl(null);
  };

  return (
    <Toolbar className="tasks_header">
      <TextField
        variant="outlined"
        size="small"
        placeholder="Search tasks"
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        sx={{flexGrow: 1 }}
      />
      {!selectedStartDate && !selectedEndDate && !status && !robotFilter && (
        <Tooltip title="Apply filters" placement="bottom">
          <IconButton
            aria-label="filter"
            aria-controls="filter-menu"
            aria-haspopup="true"
            onClick={handleFilterClick}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}

      {(selectedStartDate || selectedEndDate || status || robotFilter) && (
        <Tooltip title="Clear filters" placement="bottom">
          <IconButton aria-label="clear" onClick={handleClearFilters}>
            <ClearIcon />
          </IconButton>
        </Tooltip>
      )}

      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleFilterClose}
      >
        <MenuItem onClick={() => handleStatusFilter("All")}>All</MenuItem>
        <MenuItem
          onClick={handleStatusClick}
          selected={Boolean(statusAnchorEl)}
        >
          By Status
        </MenuItem>
        <MenuItem onClick={handleDateClick} selected={Boolean(dateAnchorEl)}>
          By Date
        </MenuItem>
        <MenuItem onClick={handleRobotClick} selected={Boolean(robotAnchorEl)}>
          By Robot
        </MenuItem>
        <Menu
          id="status-menu"
          anchorEl={statusAnchorEl}
          keepMounted
          open={Boolean(statusAnchorEl)}
          onClose={() => setStatusAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "status-menu",
          }}
        >
          <MenuItem onClick={() => handleStatusFilter("completed")}>
            Completed
          </MenuItem>
          <MenuItem onClick={() => handleStatusFilter("running")}>
            Running
          </MenuItem>
          <MenuItem onClick={() => handleStatusFilter("failed")}>
            Failed
          </MenuItem>
          <MenuItem onClick={() => handleStatusFilter("pending")}>
            Pending
          </MenuItem>
        </Menu>

        <Menu
          id="robot-menu"
          anchorEl={robotAnchorEl}
          keepMounted
          open={Boolean(robotAnchorEl)}
          onClose={() => setRobotAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "robot-menu",
          }}
        >
          {robots &&
            robots.map((robot) => (
              <MenuItem
                key={robot}
                onClick={() =>
                  handleRobotFilter(robotService.getRobotNameById(robot))
                }
              >
                {robotService.getRobotNameById(robot)}
              </MenuItem>
            ))}
        </Menu>

        {Boolean(dateAnchorEl) && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                padding: "10px",
              }}
            >
              <DesktopDatePicker
                label="Start Date"
                value={tempStartDate}
                onChange={(date) => setTempStartDate(date)}
              />
              <DesktopDatePicker
                label="End Date"
                value={tempEndDate}
                onChange={(date) => setTempEndDate(date)}
              />
              <Button onClick={handleApply}>APPLY</Button>
            </div>
          </LocalizationProvider>
        )}
      </Menu>
    </Toolbar>
  );
};

export default FilterMenu;
