import { useEffect } from "react";
import { Robot } from "../entities/Robot";
import robotService from "../services/RobotService";
import { useIndoorMapContext } from "../context/IndoorMapContext";

export const useRobots = (): Robot[] => {
  const { robots, setRobots } = useIndoorMapContext();

  useEffect(() => {
    fetchRobots();
  }, []);

  const fetchRobots = async () => {
    const data = await robotService.getData();
    setRobots(data);
  };
  return robots;
};
