import React, { useCallback, useEffect } from "react";
import {
  Alert,
  Button,
  ButtonGroup,
  Collapse,
  IconButton,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import AddLandmarkModal from "./AddLandmarkModal";
import AddObjectModal from "./AddObjectModal";
import useMap from "./useMap";
import { getCurrentUser } from "../utils/generic-functions";
import { useIndoorMapContext } from "../context/IndoorMapContext";
import "./index.scss";
import { CommonThemes } from "../utils/Themes";
import { useMappedState } from "../reducers/Store";

const Map = ({ clickEvent }: any) => {
  const currentUser = getCurrentUser();
  const { mapInteractionMode, selectedFloorId, prompt, setPrompt } =
    useIndoorMapContext();
  const {
    _init,
    updateFloorByContext,
    onClickFloor,
    // renderer,
    // sceneThemes,
    floors,
    showAddLandmarkModal,
    showObjectModal,
    selectedLandmarkFromDb,
    handleLandmarkModalClose,
    handleObjectModalClose,
    onShowFloorById,
    handleSaveLandmark,
    mapId,
    handleSaveObject,
    selectedObjectFromDb,
  } = useMap();

  const {
    camera: cameraThemes,
    scene: sceneThemes,
    renderer: rendererThemes,
  } = CommonThemes;
  const { scene, camera, renderer, orbit } = useMappedState(
    useCallback(
      (state) => {
        console.log("Use CB CALLED ::::::");
        const { x, y, z } = cameraThemes.position;
        const { width, height, container } = sceneThemes;
        const { clearColor } = rendererThemes;
        const scene = state.scene;
        const camera = state.camera;
        const renderer = state.renderer;
        const orbit = state.orbit;

        camera.position.set(x, y, z);
        const canvasElement = document.getElementById("canvas");
        if (canvasElement) {
          renderer.setSize(width, height);
          camera.aspect = width / height;
          camera.updateProjectionMatrix();
        }
        renderer.setClearColor(clearColor);
        container?.appendChild(renderer.domElement);
        renderer.domElement.style.setProperty("width", "100%", "important");
        renderer.domElement.style.setProperty("height", "auto", "important");
        return { scene, camera, renderer, orbit };
      },
      [sceneThemes, cameraThemes, rendererThemes]
    )
  );

  useEffect(() => {
    if (renderer && sceneThemes.container) {
      sceneThemes.container?.appendChild(renderer.domElement);
      _init();
    }
  }, []);

  useEffect(() => {
    console.debug("🚀 ~ useEffect ~ useEffect:selectedFloorId");

    if (selectedFloorId) {
      updateFloorByContext(selectedFloorId);
    }
  }, [selectedFloorId]);

  useEffect(() => {
    console.debug("🚀 ~ useEffect ~ useEffect:clickEvent");
    if (clickEvent) {
      onClickFloor(clickEvent);
    }
  }, [clickEvent]);

  // AT INITIAL LOADING SETTING FLOOR AS FIRST FLOOR
  useEffect(() => {
    console.debug("🚀 ~ useEffect ~ useEffect:floors");
    if (floors?.length) {
      const firstFloorId = floors[0].id;
      onShowFloorById(firstFloorId);
    }
  }, [floors]);

  function renderMapInteractionAlert() {
    if (
      currentUser &&
      ["admin", "super-admin"].includes(currentUser.role) &&
      !["/layout/tasks", "/layout/templates"].includes(window.location.pathname)
    ) {
      return (
        <Alert
          variant="outlined"
          severity="info"
          className="map_interaction_container"
        >
          {`Map interaction mode is ${mapInteractionMode ? "ON" : "OFF"}`}
        </Alert>
      );
    }
    return null;
  }

  function renderFloorButtons() {
    return (
      <Box className="button_group_container">
        <ButtonGroup
          variant="outlined"
          color="secondary"
          size="small"
          aria-label="Basic button group"
          sx={{
            display: "flex",
          }}
        >
          <Button variant="contained" color="primary" disabled>
            FLOORS
          </Button>
          {floors.map((floor: any, index: any) => (
            <Button
              key={floor.id}
              variant={floor.id === selectedFloorId ? "contained" : "outlined"}
              color="secondary"
              onClick={() => onShowFloorById(floor.id)}
            >
              {floor.name}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
    );
  }

  function renderAddLandmarkModal() {
    if (
      showAddLandmarkModal &&
      window.location.pathname === "/layout/landmarks"
    ) {
      return (
        <AddLandmarkModal
          showAddLandmarkModal={showAddLandmarkModal}
          handleLandmarkModalClose={handleLandmarkModalClose}
          event={clickEvent}
          handleSaveLandmark={handleSaveLandmark}
          landmark={selectedLandmarkFromDb}
          mapId={mapId}
          floorId={selectedFloorId}
        />
      );
    }
  }

  function renderAddObjectModal() {
    if (showObjectModal && window.location.pathname === "/layout/objects") {
      return (
        <AddObjectModal
          showAddObjectModal={showObjectModal}
          handleObjectModalClose={handleObjectModalClose}
          handleSaveObject={handleSaveObject}
          object={selectedObjectFromDb}
          mapId={mapId}
        />
      );
    }
  }

  function renderLandmarkPromptAlert() {
    if (
      currentUser &&
      ["/layout/add-tasks"].includes(window.location.pathname)
    ) {
      return (
        <>
          <Alert
            variant="outlined"
            severity="warning"
            className="map_interaction_container"
          >
            {`Click on highlighted area for selecting landmark`}
          </Alert>
        </>
      );
    }
    return null;
  }

  return (
    <Box className="map_container">
      {renderFloorButtons()}
      {renderMapInteractionAlert()}
      {prompt && renderLandmarkPromptAlert()}
      <Box
        component="div"
        ref={(ref: any) => ref && (sceneThemes.container = ref)}
        sx={{ height: "100%" }}
      ></Box>
      {renderAddLandmarkModal()}
      {renderAddObjectModal()}
    </Box>
  );
};

export default Map;
