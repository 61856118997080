import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import TaskDetails from "./TaskDetails";
import TaskHeader from "./TaskHeader";
import TaskStats from "./TaskStats";
import "./index.scss";
import MapIcons from "../../map/MapIcons";
import { useEffect } from "react";
import useMap from "../../map/useMap";
import * as THREE from "three";

const TaskCard = ({ task, expanded, handleChange }: any) => {
  console.log("Taskkkk_______",task)
  const taskId = task?.id;
  const {
    showTaskSteps,
    getAllMeshesByIds,
    removeTaskSteps,
    removeHighlight,
    removeAllIcons,
    showRobotAndMove,
    removeMovingRobot,
  } = MapIcons();
  const { raycasterList } = useMap();

  const getMapIdsFromTaskStep = (steps: any) => {
    console.log(steps,"________")
    const mapIds: any[] = [];
    if (steps) {
      steps.forEach((step: any) => {
        if (step.step_wait_time) {
          mapIds.push(mapIds[mapIds.length - 1]);
        } else {
          mapIds.push(step.step_landmark);
        }
      });
    }

    console.log("map", mapIds);
    return mapIds;
  };

  // const test = (i = 1) => {
  //   if (i > 100) {
  //     removeMovingRobot();
  //     return
  //   }

  //   console.log("*****************", i);
  //   const mesh = raycasterList[0];
  //   const meshSize = new THREE.Box3()
  //     .setFromObject(mesh)
  //     .getSize(new THREE.Vector3());
  //   const sign = i % 2 === 0 ? 2 : -2;
  //   const randomPosition: [number, number] = [
  //     mesh.position.x + 2 * Math.random() * meshSize.x - meshSize.x / 2,
  //     mesh.position.y + 1 * Math.random() * meshSize.y - meshSize.y / 2,
  //   ];
  //   showRobotAndMove(randomPosition);

  //   setTimeout(() => {
  //     test(i + 1);
  //   }, 1000);
  // };

  const showTaskIcons = async (task: any) => {
    removeHighlight();
    const mapIds = getMapIdsFromTaskStep(task.steps);
    const status = task.steps.map((step) => step.status);
    console.log("status are ..", status);
    if (mapIds.length > 0) {
      const meshes = getAllMeshesByIds(mapIds);
      if (meshes) {
        await showTaskSteps(meshes, status);
      }
    }
  };

  const removeTaskIcons = (task: any) => {
    const mapIds = getMapIdsFromTaskStep(task.steps);
    if (mapIds.length > 0) {
      const meshes = getAllMeshesByIds(mapIds);
      removeTaskSteps(meshes);
    }
  };

  const handleTaskDetails = (clickedTask: any) => {
    removeHighlight();
    if (expanded === clickedTask.id) {
      // setExpanded(false);
      removeAllIcons();
    } else {
      if (expanded !== false) {
        removeAllIcons();
      }
      // setExpanded(clickedTask.id);
      showTaskIcons(clickedTask);
      // test();
    }
  };

  useEffect(() => {
    return () => {
      if (window.location.pathname !== "/layout/landmarks") {
        removeAllIcons();
      }
    };
  }, [window.location.pathname]);

  return (
    <Box sx={{ px: 1, py: 0.5 }}>
      <Accordion
        onChange={handleChange(task.id)}
        sx={{ backgroundColor: "white" }}
        expanded={expanded === taskId}
      >
        <AccordionSummary
          className="task-card-container"
          expandIcon={<></>}
          aria-controls={`${task.id}-content`}
          id={`${task.id}-header`}
          onClick={() => handleTaskDetails(task)}
        >
          <Box className="card-content">
            <TaskHeader task={task} />
            <TaskStats task={task} />
          </Box>
        </AccordionSummary>
        <AccordionDetails id={`${task.id}-content`}>
          <TaskDetails task={task} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TaskCard;
