import { useEffect, useState } from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useIndoorMapContext } from "../../../context/IndoorMapContext";
import { useLandmarksByFloorId } from "../../../hooks/useLandmarksByFloorId";
import { generateTimeIntervals } from "../../../utils/generic-functions";
import PageTitle from "../../../components/PageTitle";

interface Step {
  id: string;
  type: string;
  step_landmark?: { label: string; value: string }[];
  step_wait_time?: { label: string; value: string }[];
}

const CreateTemplate = () => {
  const landmarkOptions = useLandmarksByFloorId();
  const { selectedMesh, setPrompt, filled, setFilled, selectedFloorId } =
    useIndoorMapContext();
  const { register, setValue, watch, control, reset } = useFormContext();
  const { fields, append, update, remove, move, replace } = useFieldArray({
    control,
    name: "steps",
  });
  const [isFirstStepSecondFilled, setIsFirstStepSecondFilled] = useState(true);

  useEffect(() => {
    setDefaultTemplateName();
    initialField();
  }, []);

  useEffect(() => {
    setSelectedLandMark();
  }, [selectedMesh]);

  useEffect(() => {
    resetStepsField();
  }, [selectedFloorId]);

  const resetStepsField = () => {
    replace([
      {
        type: "Move",
        step_landmark: [],
        step_wait_time: [],
      },
    ]);
    setIsFirstStepSecondFilled(true); // Reset the snackbar state
  };

  const setSelectedLandMark = () => {
    if (selectedMesh) {
      const id = selectedMesh?.current?.userData._id;
      if (id) {
        const landmark = landmarkOptions.find(
          (landmark) => landmark?.value === id
        );
        if (landmark) {
          const lastIndex = fields.length - 1;
          const lastStep = fields[fields.length - 1] as Step;
          const isLastStepLandmarkEmpty = !lastStep?.step_landmark?.[1];
          const data = {
            type: "Move",
            step_landmark: ["Move", landmark.value],
            step_wait_time: [],
          };
          if (isLastStepLandmarkEmpty) {
            update(lastIndex, data);
            setPrompt(false);
            setFilled(true);
          } else {
            setFilled(true);
            append(data);
            setPrompt(false);
          }

          // Check if it's the first time a 'Move' type landmark is set in any step
          const isFirstMoveLandmarkSet = fields.some(
            (step: Step) => step.type === "Move"
          );

          if (isFirstMoveLandmarkSet) {
            setIsFirstStepSecondFilled(false);
          }
        } else {
          setPrompt(true);
        }
      }
    }
  };

  const setDefaultTemplateName = () => {
    const defaultName = `${`${new Date()
      .toISOString()
      .slice(0, 10)} ${new Date().toTimeString().slice(0, 8)}`}`;
    setValue("name", defaultName);
  };

  const FieldItem = ({ field, index }) => {
    const type = watch(`steps[${index}].type`);

    return (
      <Draggable draggableId={field.id} index={index}>
        {(provided) => (
          <Box
            key={field.id}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              display: "flex",
              flexDirection: "column",
              margin: "16px 0",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Controller
                name={`steps[${index}].type`}
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={["Move", "Wait"]}
                    getOptionLabel={(option) => option}
                    value={field.value || "Move"}
                    onChange={(event, value) => {
                      field.onChange(value);
                      update(index, { ...fields[index], type: value });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Type" />
                    )}
                    clearIcon={false}
                    sx={{ width: "120px", marginRight: "5px" }}
                  />
                )}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {type === "Move" && (
                  <Controller
                    name={`steps[${index}].step_landmark`}
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        size="small"
                        fullWidth
                        options={landmarkOptions}
                        getOptionLabel={(option) => option.label}
                        clearOnEscape
                        value={
                          field.value
                            ? landmarkOptions.find(
                                (option) => option.value === field.value[1]
                              )
                            : null
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            fullWidth
                            {...params}
                            label="Select Landmark"
                            variant="outlined"
                          />
                        )}
                        onChange={(event, value) => {
                          const newValue = value ? [type, value.value] : [];
                          field.onChange(newValue);
                          if (value) {
                            setFilled(true);
                          } else {
                            setFilled(false);
                          }
                          const isFirstMoveLandmarkSet = fields.some(
                            (step: Step) => step.type === "Move"
                          );
                          if (isFirstMoveLandmarkSet) {
                            setIsFirstStepSecondFilled(false);
                          }
                        }}
                        sx={{ minWidth: "150px", width: "100%" }}
                      />
                    )}
                  />
                )}

                {type === "Wait" && (
                  <Controller
                    name={`steps[${index}].step_wait_time`}
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        size="small"
                        fullWidth
                        options={generateTimeIntervals(50)}
                        getOptionLabel={(option) => option.label}
                        clearOnEscape
                        value={
                          field.value
                            ? generateTimeIntervals(50).find(
                                (option) => option.value === field.value[1]
                              )
                            : null
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            fullWidth
                            {...params}
                            label="Select Wait Time"
                            variant="outlined"
                          />
                        )}
                        onChange={(event, value) => {
                          const newValue = value ? [type, value.value] : [];
                          field.onChange(newValue);
                          if (value) {
                            setFilled(true);
                          } else {
                            setFilled(false);
                          }
                        }}
                        sx={{ minWidth: "150px", width: "100%" }}
                      />
                    )}
                  />
                )}

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {fields.length > 1 && (
                    <IconButton
                      onClick={() => {
                        remove(index);
                        setFilled(true);
                      }}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  )}
                  {fields.length > 1 && (
                    <IconButton {...provided.dragHandleProps}>
                      <DragIndicatorIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
            <Box>
              {index < fields.length - 1 && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <ArrowDownwardIcon />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Draggable>
    );
  };

  function onDragEnd(result) {
    if (!result.destination) return;
    move(result.source.index, result.destination.index);
  }

  function initialField() {
    if (fields.length < 1) {
      append({
        type: "Move",
        step_landmark: [],
        step_wait_time: [],
      });
    }
  }

  function handleAddStep() {
    setFilled(false);
    append({
      type: "Move",
      step_landmark: [],
      step_wait_time: [],
    });
  }

  return (
    <Box sx={{ my: 1 }}>
      <TextField
        fullWidth
        label="Template Name"
        variant="outlined"
        size="small"
        {...register("name")}
      />
      <Divider sx={{ my: 2 }} />
      <PageTitle title="Steps to perform" />
      <Typography align={"center"}>Start</Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <ArrowDownwardIcon />
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-steps">
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {fields.map((field, index) => (
                <FieldItem key={field.id} index={index} field={field} />
              ))}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <ArrowDownwardIcon />
      </Box>
      <Typography align={"center"}>End</Typography>
      <Divider sx={{ my: 2 }} />
      {filled && (
        <div style={{ textAlign: "right" }}>
          <Button
            variant="outlined"
            startIcon={<AddOutlinedIcon />}
            onClick={handleAddStep}
          >
            ADD STEP
          </Button>
        </div>
      )}

      {isFirstStepSecondFilled && (
        <Snackbar open>
          <Alert
            severity="info"
            variant="filled"
            sx={{
              width: "100%",
              backgroundColor: "#000000",
              marginLeft: "95px",
            }}
          >
            Select any highlighted landmark and continue
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default CreateTemplate;
