import React from "react";
import { useIndoorMapContext } from "../context/IndoorMapContext";

export const useFloors = () => {
  const { floors } = useIndoorMapContext();
  const [floorsWithoutLandmarks, setFloorsWithoutLandmarks] = React.useState(
    []
  );

  React.useEffect(() => {
    const initialData = floors?.map((floor) => ({
      label: floor.name,
      value: floor.id,
    }));
    setFloorsWithoutLandmarks(initialData);
  }, [floors]);

  return floorsWithoutLandmarks;
};
