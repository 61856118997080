import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Toolbar,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { useIndoorMapContext } from "../../context/IndoorMapContext";
import { useFloors } from "../../hooks/useFloors";
import buildingService from "../../services/BuildingsService";
import "./index.scss";
import useMap from "../../map/useMap";
import { Robot } from "../../entities/Robot";
import robotService from "../../services/RobotService";

const AddRobot: React.FC = () => {
  const { selectedFloorId, setSelectedFloorId, addRobot } =
    useIndoorMapContext();
  const { onShowFloorById } = useMap();

  const floorsWithoutLandmarks = useFloors();
  const { control, handleSubmit, setValue } = useForm();

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const onSubmit = async (data) => {
    const { name, tag, floor_id } = data;
    const santizedData: Robot = {
      id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
      name,
      tag: data.tagId,
      floor_id: floor_id.value,
      building_id: buildingService.getBuildingIdFromLocalStorage(),
    };
    const newData = await robotService.addItem(santizedData);
    addRobot(newData);
    navigate("/layout/robots");
  };

  return (
    <Box component={"div"} className="add_robot_container">
      <Toolbar className="add_robot_toolbar">
        <IconButton
          sx={{ padding: 0, marginRight: "10px" }}
          onClick={handleBack}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <PageTitle title="ADD ROBOT" />
      </Toolbar>
      <Divider />
      <Box className="add_robot_container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="Enter name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{ mb: 1 }}
                />
              )}
            />
            <Controller
              name="tagId"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="outlined-basic"
                  label="Tag ID"
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{ mb: 1 }}
                />
              )}
            />
            <Controller
              name="floor_id"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  value={field.value}
                  onChange={(event, value) => {
                    field.onChange(value);
                    setSelectedFloorId(Number(value.value));
                    onShowFloorById(Number(value.value));
                  }}
                  options={floorsWithoutLandmarks}
                  getOptionLabel={(option) => option.label || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select floor"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Toolbar className="add_robot_footer">
            <Button
              className="outlined-button"
              sx={{ width: "49%" }}
              variant="outlined"
              onClick={handleBack}
              size="small"
            >
              Cancel
            </Button>
            <Button
              className="contained-button"
              sx={{ width: "49%" }}
              variant="contained"
              type="submit"
              size="small"
            >
              Save
            </Button>
          </Toolbar>
        </form>
      </Box>
    </Box>
  );
};

export default AddRobot;
