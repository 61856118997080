import { useCallback, useEffect } from "react";
import AppHeader from "../../components/AppHeader";
import { useAuth } from "../../context/AuthContext";
import buildingService from "../../services/BuildingsService";
import Dashboard from "../Dashboard";
import { useLocation } from "react-router-dom";

interface LayoutProps {
  onLogout: () => void;
}

const Layout = ({ onLogout }: LayoutProps) => {
  const { currentUser } = useAuth();
  const { state } = useLocation();

  const setBuildingId = useCallback(() => {
    if (state?.buildingId) {
      buildingService.setBuildingIdToLocalStorage(state?.buildingId);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser?.role === "super-admin" || currentUser?.role === "admin") {
      setBuildingId();
    }
  }, [currentUser, setBuildingId]);

  return (
    <>
      <AppHeader onLogout={onLogout} />
      <Dashboard />
    </>
  );
};

export default Layout;
