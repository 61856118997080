import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Map from "../../../map";
import "./index.scss";

function IndoorMap() {
  const canvasRef = React.useRef<HTMLDivElement>(null);
  const [clickEvent, setClickEvent] = useState<any>();
  const handleClick = (e: MouseEvent) => setClickEvent(e);

  useEffect(() => {
    const canvasElement = canvasRef.current;
    if (canvasElement) {
      canvasElement.addEventListener("click", handleClick);
    }
    return () => {
      if (canvasElement) {
        canvasElement.removeEventListener("click", handleClick);
      }
    };
  }, []);

  return (
    <Box component="div" className="indoor_map_container">
      <div id="canvas" ref={canvasRef}>
        <Map clickEvent={clickEvent} />
      </div>
    </Box>
  );
}

export default IndoorMap;
