import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import React, { useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader";
import PageTitle from "../../components/PageTitle";
import User from "../../entities/User";
import tempUserService from "../../services/TempUserService";
import UserService from "../../services/UserService";
import DrawerNavigation from "../Dashboard/DrawerNavigation";
import "./index.scss";
import { getCurrentUser } from "../../utils/generic-functions";
import buildingService from "../../services/BuildingsService";

interface Column {
  id: "name" | "email" | "role" | "active" | "buildings";
  label: string;
}

const Users = ({ onLogout }: any) => {
  const currentUser = getCurrentUser();
  const [users, setUsers] = useState<User[]>([]);
  const [open, setOpen] = useState(false);
  const [formType, setFormType] = useState<"add" | "edit">("add");
  const [formData, setFormData] = useState<User>({
    id: 0,
    name: "",
    email: "",
    role: "",
    active: true,
    buildings: [],
  });

  const columns: Column[] = [
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "role", label: "Role" },
    { id: "active", label: "Active" },
    { id: "buildings", label: "Buildings" },
  ];

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await tempUserService.getData();
      setUsers(response);
      console.log("🚀 ~ fetchUsers ~ response:", response);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleOpen = (type: "add" | "edit", user?: User) => {
    setFormType(type);
    if (type === "edit" && user) {
      setFormData(user);
    } else {
      setFormData({
        id:
          users.length > 0 ? Math.max(...users.map((user) => user.id)) + 1 : 1,
        name: "",
        email: "",
        role: "",
        active: true,
        buildings: [],
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (formType === "add") {
        await UserService.addUser(formData);
        setUsers((prevUsers) => [...prevUsers, formData]);
      } else {
        await UserService.editUser(formData);
        setUsers((prevUsers) =>
          prevUsers.map((user) => (user.id === formData.id ? formData : user))
        );
      }
      handleClose();
    } catch (error) {
      console.error(`Error ${formType}ing user:`, error);
    }
  };

  const handleDeleteUser = async (userId: number) => {
    try {
      const confirmation = window.confirm(
        "Are you sure you want to delete this user?"
      );
      if (confirmation) {
        await UserService.deleteUser(userId);
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <AppHeader onLogout={onLogout} />
      <DrawerNavigation />
      <Box className="user-container">
        <Toolbar className="header-container">
          <PageTitle title="Users" />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleOpen("add")}
          >
            Add User
          </Button>
        </Toolbar>
        <Paper className="table-container" elevation={0}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.email}
                      >
                        {columns.map((column) => {
                          const value =
                            column.id === "buildings" ? (
                              row.buildings ? row?.buildings.join(", ") : []
                            ) : column.id === "active" ? (
                              row.active  ? (
                                <Chip label="Active" color="success" />
                              ) : (
                                <Chip label="Inactive" color="error" />
                              )
                            ) : (
                              row[column.id]
                            );
                          return <TableCell key={column.id}>{value}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {formType === "add" ? "Add User" : "Edit User"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {formType === "add"
              ? "Enter details for the new user."
              : "Edit user details."}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            name="name"
            value={formData?.name}
            onChange={handleChange}
            fullWidth
            size="small"
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            value={formData?.email}
            onChange={handleChange}
            fullWidth
            size="small"
          />
          <TextField
            margin="dense"
            label="Role"
            name="role"
            value={formData?.role}
            onChange={handleChange}
            fullWidth
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" size="small">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" size="small">
            {formType === "add" ? "Add" : "Edit"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Users;
