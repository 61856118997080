import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAuth, Auth } from 'firebase/auth';

const firebaseConfig: Record<string, string> = {
    apiKey: "AIzaSyAQLpA7iEzTpdDsTApEfNX5dUqlRaoAZIk",
    authDomain: "test-38dd3.firebaseapp.com",
    projectId: "test-38dd3",
    storageBucket: "test-38dd3.appspot.com",
    messagingSenderId: "735497980324",
    appId: "1:735497980324:web:1f22d3619a27b918f3d931",
    measurementId: "G-W0X6CS2DLR"
};

export const app: FirebaseApp = initializeApp(firebaseConfig);
export const auth: Auth = getAuth(app);
