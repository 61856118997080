import { Robot } from "../entities/Robot";
import buildingService from "./BuildingsService";

class RobotService {
  private data: Robot[] = [];

  constructor() {
    this.loadData().then((data) => {
      this.data = data;
    });
  }
  async loadData(): Promise<Robot[]> {
    const buildingId = buildingService.getBuildingIdFromLocalStorage();

    try {
      const response = await fetch("/data/robots.json");
      this.data = await response.json();
      if (buildingId) {
        this.data = this.data.filter(
          (item: Robot) => item.building_id === buildingId
        );
      }
      return this.data;
    } catch (error) {
      console.error("Error loading data:", error);
      return [];
    }
  }

  async getData(): Promise<Robot[]> {
    if (!this.data || !this.data.length) {
      return this.loadData();
    } else {
      return Promise.resolve(this.data);
    }
  }

  async getBuildingNameById(id: number): Promise<string | undefined> {
    if (!this.data || !this.data.length) {
      await this.loadData();
    }
    const building = this.data.find((item) => item.id === id);
    if (building) {
      return building.name;
    } else {
      throw new Error(`Building not found for id: ${id}`);
    }
  }

  async getDataByBuildingId(buildingId: number): Promise<Robot[]> {
    console.log(
      "🚀 ~ RobotService ~ getDataByBuildingId ~ buildingId:",
      buildingId
    );
    if (!this.data || !this.data.length) {
      console.log("🚀 ~ RobotService ~ getDataByBuildingId ~ this.data :", this.data)
      await this.loadData();
    }
    const robots = this.data.filter((item) => item.building_id === buildingId);
    console.log("🚀 ~ RobotService ~ getDataByBuildingId ~ robots:", robots);
    return Promise.resolve(robots);
  }

  async getDataById(id: number) {
    console.log("Called with id", id)
    if (!this.data || !this.data.length) {
      await this.loadData();
    }

    const robot = this.data.find((item) => item.id === id)
    console.log(this.data)
    console.log("Found", robot)
    return robot
  }

  async saveData(): Promise<void> {
    console.log("Data saved:", this.data);
  }


  async addItem(item: any) {
    console.log("data from form - service", item)
    const newRobot: Robot = {
      id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
      tag: item.tag,
      name: item.name,
      floor_id: item.floor_id,
      building_id: item.building_id
    }
    this.data.push(newRobot);
    await this.saveData()
    return newRobot
  }

  deleteItem(id: number): void {
    this.data = this.data.filter((item) => item.id !== id);
  }

  async editItem(data: Robot) {
    this.data = this.data.map((item) =>
      item.id === data.id ? { ...item, ...data } : item
    );

    await this.saveData()
  }

  async getDataByFloorId(floorId: number): Promise<Robot[]> {
    if (!this.data || !this.data.length) {
      await this.loadData();
    }
    const robots = this.data.filter((item) => item.floor_id === floorId);
    return Promise.resolve(robots);
  }

  getRobotIdsByFloorId(id: number): string[] {
    const ids = []
    this.data.map((robot) => {
      if (robot.floor_id === id) {
        ids.push(robot.id)
      }
    })
    return ids
  }

  getRobotNameById(id: number): string {
    const robot = this.data.find((item) => item.id === id)
    if (robot) {
      return robot.name
    } else {
      return ""
    }
  }
}

const robotService = new RobotService();
export default robotService;
