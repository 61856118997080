import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import AuthService from "../services/AuthService";
import userService from "../services/UserService";
import { useNavigate } from "react-router-dom";
import {
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebase/firebase.config";
import User from "../entities/User";
import buildingService from "../services/BuildingsService";

interface AuthContextProps {
  login: (credentials: { email: string; password: string }) => Promise<void>;
  logout: () => Promise<void>;
  continueWithGoogle: () => Promise<void>;
  loginError: string | null;
  isUserLoggedIn: boolean;
  loading: boolean;
  currentUser: User | null;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const useAuthStatus = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkUserAuthentication = (): boolean => {
      console.log("checking authentication ✅");
      const currentUser = localStorage.getItem("current-user");
      if (currentUser) {
        console.log(`Authenticated ✅ ${currentUser}`);
      } else {
        console.log("No user found while checking authentication 😓");
      }
      return !!currentUser;
    };

    const updateAuthStatus = () => {
      const loggedIn = checkUserAuthentication();
      setIsUserLoggedIn(loggedIn);
      setLoading(false);
    };

    updateAuthStatus();

    const handleStorageChange = () => {
      const loggedIn = checkUserAuthentication();
      setIsUserLoggedIn(loggedIn);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return { isUserLoggedIn, setIsUserLoggedIn, loading, setLoading };
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [loginError, setLoginError] = useState<string | null>(null);
  const { isUserLoggedIn, setIsUserLoggedIn, loading, setLoading } =
    useAuthStatus();
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  const handleBuildingSelection = (user: User) => {
    if (
      user &&
      user.role === "staff" &&
      user.buildings &&
      user.buildings.length > 0
    ) {
      buildingService.setBuildingIdToLocalStorage(user.buildings[0]);
    }
  };
  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const userObj = await AuthService.authenticateUser({ email, password });
      console.log("🚀 ~ AuthProvider ~ userObj:", userObj);
      if (userObj) {
        handleBuildingSelection(userObj);
        setUser(userObj);
        userService.setUserToLocalStorage(userObj);
        console.log("🔑 User authenticated and saved to local storage!");
        setLoginError(null);
        setIsUserLoggedIn(true);
        setLoading(false);
        navigate(
          userObj.role === "super-admin" || userObj.role === "admin"
            ? "/buildings"
            : "/layout/tasks"
        );
      } else {
        console.log("⚠️ Invalid login credentials");
        setLoginError("Invalid login credentials");
      }
    } catch (error: any) {
      console.error("❌ Error occurred during login:", error);
      setLoginError(error?.message || "Error occurred during login");
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      userService.clearUserFromLocalStorage();
      buildingService.clearBuildingIdFromLocalStorage();
      console.log("User logged out successfully! ✅");
      setIsUserLoggedIn(false);
      setLoading(false);
      navigate("/");
    } catch (error: any) {
      console.error("❌ Error occurred during logout:", error);
    }
  };

  const continueWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const newUser = result.user;
      const email: string = newUser?.email || "";

      const userObj = await AuthService.fetchUserByEmail({ email });
      if (userObj) {
        console.log("🚀 ~ continueWithGoogle ~ userObj:", userObj);
        setUser(userObj);
        handleBuildingSelection(userObj);
        userService.setUserToLocalStorage(userObj);
        console.log("User authenticated with Google successfully! ✅");
        setLoginError(null);
        setIsUserLoggedIn(true);
        setLoading(false);
        navigate(
          userObj?.role === "super-admin" || userObj?.role === "admin"
            ? "/buildings"
            : "/layout/tasks"
        );
      } else {
        userService.clearUserFromLocalStorage();
        buildingService.clearBuildingIdFromLocalStorage();
        console.log("⚠️ Invalid login credentials");
        setLoginError("Invalid login credentials");
        setIsUserLoggedIn(false);
        setLoading(false);
        navigate("/");
      }
    } catch (error: any) {
      console.error("❌ Error signing in with Google:", error?.message);
      setLoginError(error?.message || "Error signing in with Google");
      setIsUserLoggedIn(false);
      setLoading(false);
      navigate("/");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        continueWithGoogle,
        loginError,
        isUserLoggedIn,
        loading,
        currentUser: user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};