import { TextField } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FE57DB",
    },
    text: {
      primary: "#424A54",
      secondary: "#9291A5",
    },
    background: {
      paper: "#FAFAFA",
      default: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Sofia Sans",
    h1: {
      fontSize: "18px",
      fontWeight: "700",
      color: "#424A54",
      lineHeight: "32px",
    },
    h2: {
      fontSize: "16px",
      fontWeight: "700",
      color: "#424A54",
      lineHeight: "24px",
    },
    h3: {
      fontSize: "14px",
      fontWeight: "400",
      color: "#454545",
      lineHeight: "21px",
    },
    subtitle1: {
      fontSize: "10px",
      fontWeight: "400",
      color: "#9291A5",
      lineHeight: "12px",
    },
    subtitle2: {
      fontSize: "10px",
      fontWeight: "400",
      color: "#454545",
      lineHeight: "15px",
    },
  },
});
export default theme;
