import { Box, Divider, Grid, Toolbar } from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view";
import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import { useIndoorMapContext } from "../../context/IndoorMapContext";
import { Landmark } from "../../entities/Landmark";
import { useLandmarks } from "../../hooks/useLandmarks";
import useMap from "../../map/useMap";
import { CustomTreeItem } from "../../utils/Themes";
import { groupedLandmarksByFloorId } from "../../utils/generic-functions";
import MapIcons from "../../map/MapIcons";

const Landmarks = () => {
  const landmarks = useLandmarks();
  const {
    showLandMarkIcons,
    findMeshbyMapUnitId,
    getMeshesByMapUnitIds,
    removeLandMarkIcons,
  } = MapIcons();

  const [groupedLandmarks, setGroupedLandmarks] = useState<
    Record<string, Landmark[] | Landmark[]>
  >({});

  const { onShowFloorById, highlightSelectedMesh } = useMap();
  const { setSelectedMesh, selectedFloorId, setSelectedFloorId } =
    useIndoorMapContext();

  useEffect(() => {
    const getGroupedLandmarks = () => {
      const groupedLandmarks = groupedLandmarksByFloorId(landmarks);
      setGroupedLandmarks(groupedLandmarks);
    };
    getGroupedLandmarks();
  }, [landmarks]);

  const handleLandmarkClick = (id: number) => {
    const mesh = findMeshbyMapUnitId(id);
    if (mesh) {
      setSelectedMesh(mesh);
      highlightSelectedMesh(mesh);
    }
  };

  const getMapUnitIdsByFloorId = (id: number) => {
    const ids: number[] = [];
    landmarks.forEach((item) => {
      if (item.floor_id === id) {
        ids.push(item.map_unit_id);
      }
    });
    return ids;
  };

  const displayLandmarkIcons = (floorId: number) => {
    const ids = getMapUnitIdsByFloorId(floorId);
    const meshes = getMeshesByMapUnitIds(ids);
    showLandMarkIcons(meshes);
  };

  const deleteIcon = (floorId: number) => {
    const ids = getMapUnitIdsByFloorId(floorId);
    const meshes = getMeshesByMapUnitIds(ids);
    removeLandMarkIcons(meshes);
  };

  const handleLabelClick = (id: number) => {
    onShowFloorById(Number(id));
    displayLandmarkIcons(Number(id));
  };

  useEffect(() => {
    if (window.location.pathname === "/layout/landmarks") {
      displayLandmarkIcons(selectedFloorId);
    }

    return () => {
      if (window.location.pathname !== "/layout/landmarks") {
        deleteIcon(selectedFloorId);
      }
    };
  }, [selectedFloorId, window.location.pathname, landmarks.length,handleLabelClick]);

  return (
    <Box component={"div"} sx={{ minWidth: "400px" }}>
      <Toolbar
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "8px !important",
          paddingRight: "8px !important",
        }}
      >
        <PageTitle title="LANDMARKS" />
      </Toolbar>
      <Divider />
      <Box
        sx={{
          overflowY: "auto",
          height: "calc(100vh - 144px)",
          overflowX: "hidden",
        }}
      >
        <Grid sx={{ p: 1 }}>
          <SimpleTreeView>
            {Object.entries(groupedLandmarks).map(([index, departments]) => (
              <CustomTreeItem
                key={index}
                itemId={`floor-${index}`}
                label={`Floor ${index}`}
                onClick={() => handleLabelClick(Number(index))}
              >
                {departments.map((department, index) => (
                  <CustomTreeItem
                    key={department.id}
                    itemId={`department-${index}`}
                    label={department.name}
                    onClick={() => handleLandmarkClick(department.map_unit_id)}
                  />
                ))}
              </CustomTreeItem>
            ))}
          </SimpleTreeView>
        </Grid>
      </Box>
    </Box>
  );
};

export default Landmarks;
