import { useEffect, useState } from "react";
import { Landmark } from "../entities/Landmark";
import landmarkService from "../services/landmarkService";
import buildingService from "../services/BuildingsService";
import { useIndoorMapContext } from "../context/IndoorMapContext";

export const useLandmarks = (): Landmark[] => {
  const {landmarks,setLandmarks}=useIndoorMapContext()
  const buildingId = buildingService.getBuildingIdFromLocalStorage();

  useEffect(() => {
    fetchLandmarks(buildingId);
  }, [buildingId]);

  const fetchLandmarks = async (id: number) => {
    const data = await landmarkService.getData();
    setLandmarks(data);
  };
  return landmarks;
};
