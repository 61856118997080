import React, { createContext, useState, useContext, useEffect } from "react";
import { getCurrentUser } from "../utils/generic-functions";
import { Landmark } from "../entities/Landmark";
import { ObjectItem } from "../entities/Object";
import { Robot } from "../entities/Robot";

interface IndoorMapContextProps {
  floors: any[];
  setFloors: React.Dispatch<React.SetStateAction<any[]>>;
  landmarksOfSelectedFloor: any[];
  setLandmarksofSelectedFloor: React.Dispatch<React.SetStateAction<any[]>>;
  showTaskDetails: any[];
  setShowTaskDetails: React.Dispatch<React.SetStateAction<any[]>>;
  selectedFloorId: number;
  setSelectedFloorId: React.Dispatch<React.SetStateAction<any>>;
  selectedMesh: any;
  setSelectedMesh: React.Dispatch<React.SetStateAction<any>>;
  handleSetFloors: (data: any[]) => void;
  handleSelectedMesh: (data: any) => void;
  mapSelectMode: any;
  setMapSelectMode: React.Dispatch<React.SetStateAction<any>>;
  handleSelectedFloor: (floorId: string) => void;
  mapInteractionMode: boolean;
  setMapInteractionMode: React.Dispatch<React.SetStateAction<any>>;
  landmarks: Landmark[];
  addLandmark: (newLandmark: Landmark) => void;
  editLandmark: (id: number, updatedLandmark: Landmark) => void;
  setLandmarks: any;
  objects: ObjectItem[];
  addObject: (newObject: ObjectItem) => void;
  editObject: (id: number, updatedObject: ObjectItem) => void;
  setObjects: any;
  robots: Robot[];
  addRobot: (newRobot: Robot) => void;
  editRobot: (updatedRobot: Robot) => void;
  setRobots: any;
  setPrompt: any;
  prompt: any;
  filled: any;
  setFilled: any;
}
const IndoorMapContext = createContext<IndoorMapContextProps | undefined>(
  undefined
);

export const IndoorMapProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const currentUser = getCurrentUser();
  const [filled, setFilled] = useState(false);
  const [floors, setFloors] = useState<any[]>([]);
  const [showTaskDetails, setShowTaskDetails] = useState<any[]>([]);
  const [selectedFloorId, setSelectedFloorId] = useState<number>();
  const [selectedMesh, setSelectedMesh] = useState<any>({});
  const [landmarksOfSelectedFloor, setLandmarksofSelectedFloor] = useState<
    any[]
  >([]);
  const [mapSelectMode, setMapSelectMode] = useState<boolean>(false);
  const [mapInteractionMode, setMapInteractionMode] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<boolean>(false);
  const [landmarks, setLandmarks] = useState<Landmark[]>([]);
  const [objects, setObjects] = useState<ObjectItem[]>([]);
  const [robots, setRobots] = useState<Robot[]>([]);

  const handleSelectedMesh = (data: any) => {
    if (data.name === "floorMesh") {
      if (selectedMesh?.current) {
        selectedMesh?.current?.material?.color?.set(selectedMesh?.prevColor);
      }
      const obj = {
        prevColor: data.material?.color?.getHex(),
        current: data,
      };
      setSelectedMesh(obj);
    }
  };

  const handleSetFloors = (data: any[]) => {
    if (!data) {
      setFloors([]);
      return;
    }
    const floors = data
      .map((floor: any) => {
        const userData = floor?.userData;
        if (!userData) {
          return null;
        }
        const landmarks =
          floor.children.map((mesh: any) => ({
            _id: mesh.userData._id,
            name: mesh.userData.name,
          })) || [];
        return {
          id: userData._id,
          name: `Floor ${userData._id}`,
          landmarks,
        };
      })
      .filter(Boolean);
    setFloors(floors);
  };

  const handleSelectedFloor = (floorId: string) => {
    const selectedFloor = floors.find((floor) => floor.id === Number(floorId));
    if (selectedFloor) {
      const selectedFloorUserDataWithFloorId = selectedFloor.landmarks.map(
        (landmark: any) => ({
          label: landmark.name,
          value: landmark._id,
        })
      );
      setLandmarksofSelectedFloor(selectedFloorUserDataWithFloorId);
      setMapInteractionMode(true);
    }
  };

  const addLandmark = (newLandmark: Landmark) => {
    setLandmarks((prevLandmarks) => [...prevLandmarks, newLandmark]);
  };

  const editLandmark = (id: number, updatedLandmark: Landmark) => {
    setLandmarks((prevLandmarks) =>
      prevLandmarks.map((landmark) =>
        landmark.id === id ? updatedLandmark : landmark
      )
    );
  };

  const addObject = (newObject: ObjectItem) => {
    setObjects((prevObjects) => [...prevObjects, newObject]);
  };

  const editObject = (id: number, updatedObject: ObjectItem) => {
    setObjects((prevObjects) =>
      prevObjects.map((object) => (object.id === id ? updatedObject : object))
    );
  };

  const addRobot = (newRobot: Robot) => {
    setRobots((prevRobots) => [...prevRobots, newRobot]);
  };

  const editRobot = (updatedRobot: Robot) => {
    setRobots((prevRobots) =>
      prevRobots.map((robot) =>
        robot.id === updatedRobot.id ? updatedRobot : robot
      )
    );
  };

  // useEffect(() => {
  //   if (selectedMesh?.current) {
  //     selectedMesh?.current?.material?.color?.set(selectedMesh?.prevColor);
  //   }
  // }, [selectedMesh]);

  return (
    <IndoorMapContext.Provider
      value={{
        floors,
        setFloors,
        landmarksOfSelectedFloor,
        setLandmarksofSelectedFloor,
        showTaskDetails,
        setShowTaskDetails,
        selectedFloorId,
        setSelectedFloorId,
        handleSetFloors,
        selectedMesh,
        setSelectedMesh,
        handleSelectedMesh,
        setMapSelectMode,
        mapSelectMode,
        handleSelectedFloor,
        mapInteractionMode,
        setMapInteractionMode,
        landmarks,
        addLandmark,
        editLandmark,
        setLandmarks,
        objects,
        addObject,
        editObject,
        setObjects,
        robots,
        addRobot,
        editRobot,
        setRobots,
        setPrompt,
        prompt,
        filled,
        setFilled,
      }}
    >
      {children}
    </IndoorMapContext.Provider>
  );
};

export const useIndoorMapContext = () => {
  const context = useContext(IndoorMapContext);
  if (!context) {
    throw new Error(
      "useIndoorMapContext must be used within an IndoorMapProvider"
    );
  }
  return context;
};

export default IndoorMapContext;
