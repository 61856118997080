import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Divider,
  Toolbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import TemplateCard from "../../components/TemplateCard";
import { Template } from "../../entities/Task";
import { useTemplates } from "../../hooks/useTemplates";
import "./index.scss";
import { useState } from "react";
const Templates = () => {
  const navigate = useNavigate();
  const templates: Template[] = useTemplates();
  const [expanded, setExpanded] = useState<any | false>();
  const navigateTo = () => {
    navigate("/layout/add-tasks", { state: { showOnlyTemplates: true } });
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Box component={"div"} className="templates_container">
      <Toolbar className="templates_header">
        <PageTitle title="All Templates" />
        <Button
          variant="contained"
          className="contained-button"
          onClick={navigateTo}
          size="small"
        >
          Add Template
        </Button>
      </Toolbar>
      <Divider />
      <Box className="templates_list">
        {templates.map((template: any) => (
          <TemplateCard
            template={template}
            key={template?.id}
            handleChange={handleChange}
            expanded={expanded}
          />
        ))}
        {templates.length === 0 && (
          <Alert severity="info" sx={{ m: 1, p: 1 }}>
            No templates present
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default Templates;
