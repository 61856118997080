import { useEffect, useState } from "react";
import { Task } from "../entities/Task";
import taskService from "../services/TaskService";
import { useIndoorMapContext } from "../context/IndoorMapContext";

export const useTasks = (): Task[] => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const { selectedFloorId } = useIndoorMapContext();

  useEffect(() => {
    if (selectedFloorId) {
      fetchTasks();
    }
  }, [selectedFloorId]);
  const fetchTasks = async () => {
    const initialData = await taskService.getData();
    const filteredTasks = initialData
      .filter((task) => task.floor_id === selectedFloorId)
      .sort((a, b) => {
        return (
          new Date(b.start_time).valueOf() - new Date(a.start_time).valueOf()
        );
      });
    filteredTasks.map((task) => {
      task.steps.sort((a, b) => {
        return (
          new Date(a.start_time).valueOf() - new Date(b.start_time).valueOf()
        );
      });
    });
    setTasks(filteredTasks);
  };

  return tasks;
};
