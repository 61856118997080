import * as React from "react";
import "./index.scss";
import { Typography } from "@mui/material";
import { PageTitleProps } from "../../entities/generic";

const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
  return <Typography variant="h1">{title}</Typography>;
};

export default PageTitle;
