import User from "../entities/User";

class TempUserService {
  private data: User[] = [];
  private loggedUser: User | null = null;

  constructor() {
    this.loadData().then((data) => {
      this.data = data;
    });
    this.loggedUser = this.getLoggedUser();
  }
  async loadData(): Promise<User[]> {
    try {
      const response = await fetch("/data/users.json");
      this.data = await response.json();
      return this.data;
    } catch (error) {
      console.error("Error loading data:", error);
      return [];
    }
  }

  async getData(): Promise<User[]> {
    if (!this.data || !this.data.length) {
      return this.loadData();
    } else {
      return Promise.resolve(this.data);
    }
  }

  getUserNameByUserId(userId: number) {
    try {
      const user = this.data.find((item) => item.id === userId);
      if (user) {
        return user.name;
      } else {
        throw new Error(`User not found for ${user}`);
      }
    } catch (error) {
      console.log("Error occured while finding user name by userId:", error);
      throw error;
    }
  }

  getLoggedUser() {
    try {
      const user = localStorage.getItem("current-user");
      return user ? JSON.parse(user) : null;
    } catch (error) {
      console.log("Error occured while finding logged user:", error);
      throw error;
    }
  }

  addItem(item: User): void {
    this.data.push(item);
  }

  deleteItem(id: number): void {
    this.data = this.data.filter((item) => item.id !== id);
  }

  editItem(id: number, updatedItem: Partial<User>): void {
    this.data = this.data.map((item) =>
      item.id === id ? { ...item, ...updatedItem } : item
    );
  }
  
}

const tempUserService = new TempUserService();
export default tempUserService;
