import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DRAWER_WIDTH } from "../../../constants/constants";
import { getCurrentUser } from "../../../utils/generic-functions";
import "./index.scss";
import { Alert } from "@mui/material";
import buildingService from "../../../services/BuildingsService";

const drawerWidth = DRAWER_WIDTH;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  zIndex: theme.zIndex.drawer + 1,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  zIndex: theme.zIndex.drawer + 1,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const getItemText = (role: any) => {
  return role === "admin" || role === "super-admin" ? "All Tasks" : "My Tasks";
};

const DrawerNavigation = () => {
  const currentUser = getCurrentUser();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const commonItems = [
    {
      text: getItemText(currentUser?.role),
      icon: <PublishedWithChangesOutlinedIcon />,
      path: "/layout/tasks",
    },
    {
      text: "Templates",
      icon: <DescriptionOutlinedIcon />,
      path: "/layout/templates",
    },
  ];

  const conditionalItems = [
    {
      text: "Performance",
      icon: <SpeedOutlinedIcon />,
      path: "/performance",
    },
    {
      text: "Landmarks",
      icon: <PinDropOutlinedIcon />,
      path: "/layout/landmarks",
    },
    {
      text: "Object",
      icon: <ViewInArOutlinedIcon />,
      path: "/layout/objects",
    },
    {
      text: "Robots",
      icon: <SmartToyOutlinedIcon />,
      path: "/layout/robots",
    },
    {
      text: "Users",
      icon: <PeopleAltOutlinedIcon />,
      path: "/users",
    },
  ];

  const listItems = [
    ...commonItems,
    ...(currentUser?.role === "admin" || currentUser?.role === "super-admin"
      ? conditionalItems
      : []),
  ];

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseEnter={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
      className="drawer-container"
    >
      <DrawerHeader></DrawerHeader>
      <List className="list-container">
        {listItems.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            sx={{ display: "block" }}
            className="list-item-container"
          >
            <ListItemButton
              component={Link}
              to={item.path}
              onClick={handleDrawerClose}
              className="list-item-button"
              sx={{ mr: open ? 3 : "auto" }}
            >
              <ListItemIcon
                sx={{
                  mr: open ? 3 : "auto",
                }}
                className="list-item-icon"
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default DrawerNavigation;
