import * as THREE from 'three';
import geometry from '../objects/Geometry';
import useMap from './useMap';
import landmarkService from '../services/landmarkService';

const MapIcons = () => {
    const { raycasterList } = useMap();

    // FINDING MESH USING MAP_UNIT_ID
    const findMeshbyMapUnitId = (mapUnitId: number) => {
        try {
            const mesh = raycasterList.find(
                (item: any) => item?.userData?._id === mapUnitId
            );
            console.log("Mesh is", mesh);
            return mesh;
        } catch (error) {
            console.log("Error while finding mesh by mapUnitId", error);
        }
    };

    // GETING MESHES FROM  MAP UNIT IDS
    const getMeshesByMapUnitIds = (ids: number[]): THREE.Mesh[] => {
        console.log("Called with ", ids);
        const meshes: THREE.Mesh[] = [];
        raycasterList.forEach((mesh: THREE.Mesh) => {
            if (ids.includes(mesh.userData?._id)) {
                meshes.push(mesh);
            }
        });
        console.log("find mesh", meshes);
        return meshes;
    };

    //GETTING MESHES WITH REPEATING IDS
    const getAllMeshesByIds = (ids: number[]): THREE.Mesh[] => {
        const meshes: THREE.Mesh[] = [];
        ids.forEach((id) => {
            raycasterList.forEach((mesh: THREE.Mesh) => {
                if (mesh.userData._id === id) {
                    meshes.push(mesh);
                }
            });
        });
        console.log("Founded Mesh", meshes);
        return meshes;
    };

    // SHOW LANDMARK ICON IN MAP
    const showLandMarkIcons = (meshes: THREE.Mesh[]) => {
        meshes.forEach((mesh: THREE.Mesh) => {
            const existingSprite = mesh.children.find(
                (child) => child.name === 'landmarkIcon'
            );
            if (!existingSprite) {
                const meshMaterial = mesh.material as THREE.MeshPhongMaterial;

                if (!mesh.userData.originalColor) {
                    mesh.userData.originalColor = meshMaterial.color.clone();
                }

                meshMaterial.color.set(0x00ff00);

                const labelText = landmarkService.getNameByMapUnitId(mesh.userData?._id);
                const sprite = geometry.setLandmarkNames(labelText, mesh.userData?.center);
                sprite.name = "landmarkIcon";
                mesh.add(sprite);
            }
        });
    };

    // REMOVE LANDMARK ICONS IN MAP
    const removeLandMarkIcons = (meshes: THREE.Mesh[]) => {
        meshes.forEach((mesh: THREE.Mesh) => {
            const landmarkIcon = mesh.children.find(
                (child) => child.name === "landmarkIcon"
            );
            if (landmarkIcon) {
                mesh.remove(landmarkIcon);

                const meshMaterial = mesh.material as THREE.MeshPhongMaterial;
                if (mesh.userData.originalColor) {
                    meshMaterial.color.copy(mesh.userData.originalColor);
                }
            }
        });
    };

    // SHOW OBJECT ICON IN MAP
    const showObjectIcon = (meshes: THREE.Object3D[]) => {
        meshes.forEach((mesh) => {
            const existingSprite = mesh.children.find(
                (child) => child.name === "objectIcon"
            );
            if (!existingSprite) {
                const sprite = geometry.setObjectIcon(mesh.userData?.center);
                sprite.name = "objectIcon";
                mesh.add(sprite);
            }
        });
    };

    // REMOVE OBJECT ICONS IN MAP
    const removeObjectIcons = (meshes: THREE.Mesh[]) => {
        meshes.forEach((mesh) => {
            const objectIcon = mesh.children.find(
                (child) => child.name === "objectIcon"
            );
            if (objectIcon) {
                mesh.remove(objectIcon);
            }
        });
    };

    // SHOW TASK STEPS IN MAP
    const showTaskSteps = async (meshes: THREE.Mesh[], status: string[]) => {
        let addedSprite: THREE.Object3D[] = [];
        let icon: any;

        for (let index = 0; index < meshes.length; index++) {
            const mesh: THREE.Mesh = meshes[index];

            if (addedSprite.includes(mesh)) {
                const center: [number, number] = [
                    mesh.userData?.center[0] + 30,
                    mesh.userData?.center[1] + 30,
                ];

                if (status[index] === "running") {
                    icon = '/assets/loading.svg'; // Animated SVG
                } else if (status[index] === "pending") {
                    icon = null
                } else if (status[index] === "completed") {
                    icon = '/assets/waiting.svg' // Hourglass SVG
                } else if (status[index] === "failed") {
                    icon = '/assets/failed.svg' // Failed SVG
                } else {
                    icon = null
                }

                try {
                    if (icon) {
                        const sprite = await geometry.setTaskIcon(icon, center);
                        sprite.name = "taskIcon";
                        addedSprite.push(mesh);
                        mesh.add(sprite);
                    }
                } catch (error) {
                    console.error('Error adding sprite:', error);
                }
            } else {
                try {
                    if (status[index] === "running") {
                        icon = '/assets/loading.svg'; // Animated SVG
                    } else if (status[index] === "pending") {
                        icon = null
                    } else if (status[index] === "completed") {
                        icon = '/assets/moved.svg' // Move SVG
                    } else if (status[index] === "failed") {
                        icon = '/assets/failed.svg' // Failed SVG
                    } else {
                        icon = null
                    }
                    if (icon) {
                        const sprite = await geometry.setTaskIcon(icon, mesh.userData?.center);
                        sprite.name = "taskIcon";
                        addedSprite.push(mesh);
                        mesh.add(sprite);
                    }
                } catch (error) {
                    console.error('Error adding sprite:', error);
                }
            }

            // const meshMaterial = mesh.material as THREE.MeshPhongMaterial;

            // if (!mesh.userData.originalColor) {
            //     mesh.userData.originalColor = meshMaterial.color.clone();
            // }

            // meshMaterial.color.set(0x00ff00);

        }

        showLandMarkIcons(meshes)
    };

    // REMOVE TASK STEPS IN MAP
    const removeTaskSteps = (meshes: THREE.Mesh[]) => {
        meshes.forEach((mesh) => {
            while (mesh.children.length > 0) {
                mesh.remove(mesh.children[0]);
            }
        });
    };


    // SHOW ROBOT ICON
    const showRobotIcons = (robots: number) => {
        for (let i = 0; i < robots; i++) {
            let mesh = raycasterList[0];
            const existingSprite = mesh.children.find(
                (child) => child.name === `robotIcon${i}`
            );

            if (!existingSprite) {
                const meshSize = new THREE.Box3()
                    .setFromObject(mesh)
                    .getSize(new THREE.Vector3());

                const sign = i % 2 === 0 ? 2 : -2;

                const randomPosition = new THREE.Vector3(
                    mesh.position.x + sign * Math.random() * meshSize.x - meshSize.x / 2,
                    mesh.position.y + sign * Math.random() * meshSize.y - meshSize.y / 2,
                    35
                );

                const sprite = geometry.setRobotIcon(
                    randomPosition.x,
                    randomPosition.y,
                    randomPosition.z
                );
                sprite.name = `robotIcon${i}`;
                mesh.add(sprite);
            }
        }
    };

    // REMOVE ROBOT ICONS IN MAP
    const removeRobotIcons = () => {
        if (raycasterList.length > 0) {
            const mesh = raycasterList[0];
            if (!mesh) {
                console.error("Raycaster mesh not found");
                return;
            }
            while (mesh.children.length) {
                mesh.remove(mesh.children[0]);
            }
        }
    };

    // REMOVE SPRITE FROM MAP
    const removeSpriteFromMap = (name: string) => {
        raycasterList.forEach((mesh: any) => {
            const icon = mesh.getObjectByName(name);
            if (icon) {
                mesh.remove(icon);
            }
        });
    };

    // MESH COLOR CHANGE
    const highlightSingleMesh = (id: number) => {

        raycasterList.forEach((mesh: THREE.Mesh) => {
            const meshMaterial = mesh.material as THREE.MeshPhongMaterial;
            if (mesh.userData.originalColor) {
                meshMaterial.color.copy(mesh.userData.originalColor);
            }
        });

        const mesh: THREE.Mesh = findMeshbyMapUnitId(id);
        if (mesh) {
            const meshMaterial = mesh.material as THREE.MeshPhongMaterial;

            if (!mesh.userData.originalColor) {
                mesh.userData.originalColor = meshMaterial.color.clone();
            }

            meshMaterial.color.set(0x00ff00);
        } else {
            return
        }
    }

    const removeHighlight = () => {
        raycasterList.forEach((mesh: THREE.Mesh) => {
            const meshMaterial = mesh.material as THREE.MeshPhongMaterial;
            if (mesh.userData.originalColor) {
                meshMaterial.color.copy(mesh.userData.originalColor);
            }
        });
    }

    const removeAllIcons = () => {
        raycasterList.forEach((mesh) => {
            while (mesh.children.length > 0) {
                mesh.remove(mesh.children[0]);
            }
            const meshMaterial = mesh.material as THREE.MeshPhongMaterial;
            if (mesh.userData.originalColor) {
                meshMaterial.color.copy(mesh.userData.originalColor);
            }
        });
    }

    const showRobotAndMove = (position: [number, number]) => {
        const mesh: THREE.Mesh = raycasterList[0]
        const existingSprite = mesh.children.find(
            (child) => child.name === "robotIcon"
        );
        if (!existingSprite) {
            const sprite = geometry.setRobotIcon(
                position[0],
                position[1],
                35
            );
            sprite.name = "robotIcon";
            mesh.add(sprite);
        } else {
            existingSprite.position.set(position[0], position[1], 35)
        }
    }

    const removeMovingRobot = () => {
        const mesh: THREE.Mesh = raycasterList[0]
        while (mesh.children.length > 0) {
            mesh.remove(mesh.children[0]);
        }
    }

    return {
        showLandMarkIcons,
        showObjectIcon,
        showTaskSteps,
        showRobotIcons,
        findMeshbyMapUnitId,
        getMeshesByMapUnitIds,
        getAllMeshesByIds,
        removeLandMarkIcons,
        removeObjectIcons,
        removeTaskSteps,
        removeRobotIcons,
        removeSpriteFromMap,
        highlightSingleMesh,
        removeHighlight,
        removeAllIcons,
        showRobotAndMove,
        removeMovingRobot
    };
};

export default MapIcons;
