const HistoryService = {
  getHistory: async () => {
    try {
      const response = await fetch(`/data/history.json`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default HistoryService;
