import React, { useState, Fragment, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import TaskCard from "../../components/TaskCard";
import { Task } from "../../entities/Task";
import { useTasks } from "../../hooks/useTasks";
import templateService from "../../services/TemplateService";
import { DateFromIso, getCurrentUser } from "../../utils/generic-functions";
import "./index.scss";
import { useIndoorMapContext } from "../../context/IndoorMapContext";
import FilterMenu from "../../components/TaskFilter";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import robotService from "../../services/RobotService";

dayjs.extend(utc);

const Tasks = () => {
  const tasks: Task[] = useTasks();
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const [expanded, setExpanded] = useState<any | false>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [robots, setRobots] = useState<string[] | null>(null);
  const [robotFilter, setRobotFilter] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [selectedStartDate, setSelectedStartDate] =
    useState<dayjs.Dayjs | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<dayjs.Dayjs | null>(
    null
  );
  const { selectedFloorId } = useIndoorMapContext();

  useEffect(() => {
    setStatus("");
    setSelectedEndDate(null);
    setSelectedStartDate(null);
    setSearchQuery("");
    setRobotFilter("");
  }, [selectedFloorId]);

  useEffect(() => {
    const data = robotService.getRobotIdsByFloorId(selectedFloorId);
    setRobots(data);
  }, [selectedFloorId]);

  const navigateToAddTasks = () => {
    navigate("/layout/add-tasks");
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  function getItemText(role: any) {
    return role === "admin" || role === "super-admin"
      ? "ALL TASKS"
      : "MY TASKS";
  }

  let lastDate = "";

  const filteredTasks = tasks
    .filter((task) => {
      if (status && status !== "All") {
        return task.status === status;
      }
      return true;
    })
    .filter((task) =>
      templateService
        .getTaskNameByTemplateId(task.template_id)
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .filter((task) => {
      if (selectedStartDate && selectedEndDate) {
        const taskDate = dayjs.utc(task.start_time);
        return (
          taskDate.isAfter(selectedStartDate) &&
          taskDate.isBefore(selectedEndDate)
        );
      }
      return true;
    })
    .filter((task) => {
      if (robotFilter) {
        return task.robot_id === robotFilter;
      }
      return true;
    });

  return (
    <Box component={"div"} className="tasks_container">
      <Toolbar className="tasks_header">
        <PageTitle title={getItemText(currentUser.role)} />
        <Button
          variant="contained"
          className="contained-button"
          onClick={navigateToAddTasks}
          disabled={
            currentUser.role === "admin" || currentUser.role === "super-admin"
          }
        >
          Run Task
        </Button>
      </Toolbar>

      <FilterMenu
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        setStatus={setStatus}
        setSelectedStartDate={setSelectedStartDate}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        setSelectedEndDate={setSelectedEndDate}
        status={status}
        robots={robots}
        robotFilter={robotFilter}
        setRobotFilter={setRobotFilter}
      />
      <Divider />
      <Box
        className="tasks_list"
        sx={{ overflowY: "auto", height: "calc(100vh - 168px)", my: 3 }}
      >
        {filteredTasks.length > 0 ? (
          filteredTasks.map((task: Task) => {
            const currentDate = DateFromIso(task.start_time);
            const showDate = currentDate !== lastDate;
            lastDate = currentDate;

            return (
              <Fragment key={task.id}>
                {showDate && (
                  <Divider>
                    <Typography variant="body2">{currentDate}</Typography>
                  </Divider>
                )}
                <TaskCard
                  key={task.id}
                  task={task}
                  taskName={templateService.getTaskNameByTemplateId(
                    task.template_id
                  )}
                  handleChange={handleChange}
                  expanded={expanded}
                />
              </Fragment>
            );
          })
        ) : (
          <Alert severity="error" sx={{ m: 1, p: 1 }}>
            {searchQuery && `No tasks found with name ${searchQuery}`}
            {status &&
              `No tasks found with status ${status} on floor ${selectedFloorId}`}
            {robotFilter && `No tasks found for robot ${robotFilter}`}
            {!searchQuery &&
              !status &&
              !selectedEndDate &&
              !selectedStartDate &&
              !robotFilter &&
              selectedFloorId &&
              ` No tasks running on floor ${selectedFloorId}`}
            {selectedStartDate &&
              selectedEndDate &&
              `No tasks were found within ${DateFromIso(
                selectedEndDate
              )} to ${DateFromIso(selectedEndDate)}`}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default Tasks;
