import * as React from "react";
// import RunningTasks from "../RunningTasks";
const Register = () => {
  return (
  <div>
    Register
    {/* Can verify the design for Running Task Modal by uncommenting next line */}
  </div>
  );
};

export default Register;
