import { ObjectItem } from "../entities/Object";
import buildingService from "./BuildingsService";

class ObjectService {
  private data: ObjectItem[] = [];

  constructor() {
    this.loadData().then((data) => {
      this.data = data;
    });
  }

  async loadData(): Promise<ObjectItem[]> {
    const buildingId = buildingService.getBuildingIdFromLocalStorage();

    try {
      const response = await fetch("/data/objects.json");
      this.data = await response.json();
      if (buildingId) {
        this.data = this.data.filter(
          (item: ObjectItem) => item.building_id === buildingId
        );
      }
      return this.data;
    } catch (error) {
      console.error("Error loading data:", error);
      return [];
    }
  }

  async getData(): Promise<ObjectItem[]> {
    if (!this.data || !this.data.length) {
      return this.loadData();
    } else {
      return Promise.resolve(this.data);
    }
  }

  async getDataById(id: number): Promise<ObjectItem | undefined> {
    return this.data.find((item) => item.id === id);
  }

  async getDataByFloorId(id: number): Promise<ObjectItem[]> {
    return this.data.filter((item) => item.floor_id === id);
  }

  async getDataByMapUnitId(id: number): Promise<ObjectItem | null> {
    return this.data.find((item) => item.map_unit_id === id) || null;
  }

  async getDataByBuildingId(buildingId: number): Promise<ObjectItem[]> {
    console.log("Called")
    if (!this.data || !this.data.length) {
      await this.loadData();
    }
    const objects = this.data.filter((item) => item.building_id === buildingId);
    return Promise.resolve(objects);
  }

  getObjectNameById(id: number): string {
    const object = this.data.find((item) => item.id === id);
    if (object) {
      return object.name;
    } else {
      console.log("No object found with id:", id);
      return "";
    }
  }

  async saveData(): Promise<void> {
    console.log("Data saved:", this.data);
  }

  async addItem(item: ObjectItem): Promise<ObjectItem> {
    console.log("item from form is", item)
    item.id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
    this.data.push(item);
    console.log("New Object added:", item);
    await this.saveData();
    console.log(this.data, "After save")
    return item;
  }

  async deleteItem(id: number): Promise<void> {
    this.data = this.data.filter((item) => item.id !== id);
    await this.saveData();
  }

  async editItem(id: number, updatedItem: ObjectItem): Promise<void> {
    console.log("called edit from object service class");
    const index = this.data.findIndex((item) => item.id === id);

    if (index !== -1) {
      if (updatedItem.name !== undefined) {
        this.data[index].name = updatedItem.name;
      }

      if (updatedItem.tag !== undefined) {
        this.data[index].tag = updatedItem.tag;
      }

      console.log("edited", this.data[index]);
      await this.saveData();
    } else {
      console.error(`Object with id ${id} not found.`);
    }
  }

  getMapUnitIdByFloorId(id: number): number[] {
    const ids: number[] = [];
    this.data.forEach((item) => {
      if (item.floor_id === id) {
        ids.push(item.map_unit_id);
      }
    });
    return ids;
  }

  getMapUnitIdByObjectName(name: string): number | undefined {
    console.log(name, "LLLLL");
    const object = this.data.find((item) => item.name === name);
    if (object) {
      return object.map_unit_id;
    } else {
      console.log("No object found with name:", name);
      return undefined;
    }
  }
}

const objectService = new ObjectService();
export default objectService;
