import React, { useEffect } from "react";
import { Box, Divider, IconButton, Toolbar } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./index.scss";
import { useLandmarks } from "../../hooks/useLandmarks";
import { useIndoorMapContext } from "../../context/IndoorMapContext";
import MapIcons from "../../map/MapIcons";
import CreateTask from "./CreateTask";

const AddTasks = () => {
  const navigate = useNavigate();
  const { showLandMarkIcons, getMeshesByMapUnitIds, removeLandMarkIcons } =
    MapIcons();
  const { state } = useLocation();
  const { selectedFloorId } = useIndoorMapContext();

  const landmarks = useLandmarks();

  useEffect(() => {
    if (window.location.pathname === "/layout/add-tasks") {
      displayLandmarkIcons(selectedFloorId);
    }

    return () => {
      if (window.location.pathname !== "/layout/add-tasks") {
        deleteIcon(selectedFloorId);
      }
    };
  }, [selectedFloorId, landmarks.length]);

  const getMapUnitIdsByFloorId = (id: number) => {
    const ids: number[] = [];
    landmarks.forEach((item) => {
      if (item.floor_id === id) {
        ids.push(item.map_unit_id);
      }
    });
    return ids;
  };

  const displayLandmarkIcons = (floorId: number) => {
    const ids = getMapUnitIdsByFloorId(floorId);
    const meshes = getMeshesByMapUnitIds(ids);
    showLandMarkIcons(meshes);
  };
  const handleBack = () => {
    navigate(-1);
  };

  const deleteIcon = (floorId: number) => {
    const ids = getMapUnitIdsByFloorId(floorId);
    const meshes = getMeshesByMapUnitIds(ids);
    removeLandMarkIcons(meshes);
  };

  return (
    <Box component={"div"} className="add_task_container">
      <Toolbar className="add_task_toolbar">
        <Box className="add_task_box">
          <IconButton className="add_task_back_button" onClick={handleBack}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <PageTitle title="Add Tasks" />
        </Box>
      </Toolbar>
      <Divider />
      <Box className="create_task_container">
        <CreateTask />
      </Box>
    </Box>
  );
};

export default AddTasks;
