import { useEffect, useState } from "react";
import { useIndoorMapContext } from "../context/IndoorMapContext";
import landmarkService from "../services/landmarkService";

type LandmarkWithIdAndName = {
  map_unit_id: number;
  name: string;
};

export const useLandmarksByFloorId = () => {
  const { selectedFloorId } = useIndoorMapContext();
  const [landmarks, setLandmarks] = useState<{ label: string; value: number }[]>(
    []
  );

  useEffect(() => {
    landmarkService
      .getDataByFloorId(selectedFloorId)
      .then((landmarks: LandmarkWithIdAndName[]) =>
        setLandmarks(
          landmarks.map((landmark: LandmarkWithIdAndName) => ({
            label: landmark.name,
            value: landmark.map_unit_id,
          }))
        )
      );
  }, [selectedFloorId]);

  return landmarks;
};
