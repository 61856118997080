import {
  Alert,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { formatAMPM } from "../../../utils/generic-functions";
import { Task } from "../../../entities/Task";
import landmarkService from "../../../services/landmarkService";
import MapIcons from "../../../map/MapIcons";

const TaskDetails = ({ task }: any) => {
  const { highlightSingleMesh } = MapIcons();
  console.log("🚀 ~ TaskDetails ~ task:", task);
  function StepIcon(stepIconProps: any, props: any) {
    let color = "#D9D9D9";
    if (props === "completed") {
      color = "#15A136";
      return <CheckCircleIcon sx={{ color: color }} />;
    } else if (props === "running") {
      color = "#FFC125";
      return <DonutLargeIcon sx={{ color: color }} />;
    } else if (props === "pending") {
      color = "#D9D9D9";
      return <CircleIcon sx={{ color: color }} />;
    } else {
      color = "#FF0000";
      return <ErrorOutlineIcon sx={{ color: color }} />;
    }
  }

  const highlightLandmark = (id: number) => {
    highlightSingleMesh(id);
  };

  return (
    <Stepper orientation="vertical">
      {task?.steps?.map((step: any, i: any) => (
        <Step key={i}>
          <StepLabel
            StepIconComponent={(StepIconProps) =>
              StepIcon(StepIconProps, step.status)
            }
            sx={{ p: 0 }}
          >
            <List dense disablePadding>
              <ListItem disableGutters disablePadding>
                <Typography>{step.step_id}</Typography>
                <ListItemText
                  primary={
                    <>
                      {step.start_time
                        ? formatAMPM(new Date(step.start_time))
                        : "Not yet started"}{" "}
                      {step.start_time && step.end_time
                        ? "- " + formatAMPM(new Date(step.end_time))
                        : ""}
                      <br />
                      {step.type} -{" "}
                      {step.step_landmark ? (
                        <a
                          href="#"
                          className="anchor"
                        >
                          {landmarkService.getLandmarkNameById(
                            step.step_landmark
                          )}
                        </a>
                      ) : (
                        ""
                      )}
                      {step.type === "Wait"
                        ? step.step_wait_time + " seconds"
                        : ""}
                      <br />
                    </>
                  }
                  secondary={
                    step.status === "running" || step.status === "failed" ? (
                      <Alert
                        sx={{ padding: "0px 8px", mt: 1, borderRadius: "8px" }}
                        severity="warning"
                      >
                        {step.status_message}
                      </Alert>
                    ) : (
                      ""
                    )
                  }
                />
              </ListItem>
            </List>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default TaskDetails;
