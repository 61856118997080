import {
  List,
  ListItem,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import templateService from "../../../services/TemplateService";
import landmarkService from "../../../services/landmarkService";

const TemplateDetails = ({ template }: any) => {
  console.log("🚀 ~ TemplateDetails ~ template:", template);
  const steps = template.steps;

  return (
    <Stepper orientation="vertical">
      {steps?.map((step: any, i: any) => (
        <Step key={i}>
          <StepLabel sx={{ p: 0 }}>
            <List dense disablePadding>
              <ListItem
                disableGutters
                disablePadding
                sx={{ backgroundColor: "#f9f9f9" }}
              >
                <Typography variant="subtitle2">{step?.step_id}</Typography>
                <ListItemText
                  sx={{ pl: 1, fontWeight: "bold" }}
                  primary={
                    <>
                      {step?.type} -{" "}
                      {step?.step_landmark ? (
                        <a href="#" className="anchor">
                          {landmarkService.getLandmarkNameById(
                            step?.step_landmark
                          )}
                        </a>
                      ) : (
                        ""
                      )}
                      {step?.type === "Wait"
                        ? step?.step_wait_time + " seconds"
                        : ""}
                    </>
                  }
                />
              </ListItem>
            </List>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default TemplateDetails;
