import { createStore, Store } from "redux";
import { create } from "redux-react-hook";
import * as THREE from "three";
import reducer from ".";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export interface MapState {
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  renderer: THREE.WebGLRenderer;
  orbit: any;
}

export type Action =
  | {
      type: "set scene";
      scene: THREE.Scene;
    }
  | {
      type: "set orbit";
      orbit: any;
    };

export function makeStore(): Store<MapState, Action> {
  return createStore(reducer, INITIAL_STATE);
}

const camera = new THREE.PerspectiveCamera(
  25,
  window.innerWidth / window.innerHeight,
  0.1,
  2000
);
const renderer = new THREE.WebGLRenderer({ antialias: true });
export const INITIAL_STATE: MapState = {
  scene: new THREE.Scene(),
  camera: camera,
  renderer: renderer,
  orbit: new OrbitControls(camera, renderer.domElement),
};

export const { StoreContext, useDispatch, useMappedState } = create<
  MapState,
  Action,
  Store<MapState, Action>
>();
