import { Backdrop, Button, Fade, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import PageTitle from "../components/PageTitle";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { getCurrentUser } from "../utils/generic-functions";
import { ObjectItem } from "../entities/Object";
import buildingService from "../services/BuildingsService";

const AddObjectModal = ({
  showAddObjectModal,
  handleObjectModalClose,
  handleSaveObject,
  object,
  mapId
}: {
  showAddObjectModal: any;
  handleObjectModalClose: () => any;
  handleSaveObject: (data: any) => void;
  object: any;
  mapId:number
}) => {
  const currentUser = getCurrentUser();
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    p: 2,
  };

  const methods = useForm<ObjectItem>({
    defaultValues: {
      tag: object?.tag ? object.tag : "",
      name: object?.name ? object.name : "",
      building_id: buildingService.getBuildingIdFromLocalStorage(),
      map_unit_id:mapId
    },
  });

  const { handleSubmit, control } = methods;

  console.log("Data id to modal is",mapId)

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={showAddObjectModal}
      onClose={handleObjectModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showAddObjectModal}>
        <Box sx={style}>
          <PageTitle title="Add Object" />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSaveObject)}>
              <Controller
                name="tag"
                control={control}
                rules={{ required: true }}
                render={({ field, formState: { errors } }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="Object tag name"
                    variant="outlined"
                    sx={{ width: "100%", my: 2 }}
                    size="small"
                    disabled={currentUser.role === "staff"}
                    error={!!errors.name}
                    helperText={errors.name ? "Tag is required" : ""}
                  />
                )}
              />
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field, formState: { errors } }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="Object name"
                    variant="outlined"
                    sx={{ width: "100%", my: 2 }}
                    size="small"
                    disabled={currentUser.role === "staff"}
                    error={!!errors.name}
                    helperText={errors.name ? "Name is required" : ""}
                  />
                )}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  margin: "10px 0 0 0",
                }}
              >
                <Button
                  fullWidth
                  className="outlined-button"
                  variant="text"
                  size="small"
                  onClick={handleObjectModalClose}
                  sx={{ mr: 1 }}
                >
                  Close
                </Button>

                <Button
                  fullWidth
                  className="contained-button"
                  variant="contained"
                  type="submit"
                  size="small"
                  disabled={currentUser?.role === "staff"}
                >
                  {object ? "Update" : "Save"}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddObjectModal;
