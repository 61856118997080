import { Box, Button, Divider, Toolbar } from "@mui/material";
import * as React from "react";
import PageTitle from "../../components/PageTitle";

const ScheduledTasks = () => {
  return (
    <Box component={"div"} sx={{ minWidth: "400px" }}>
    <Toolbar
      sx={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: "16px !important",
        paddingRight: "16px !important",
      }}
    >
      <PageTitle title="Scheduled Tasks" />
      <Button variant="contained"  size="small"
>Schedule Task</Button>
    </Toolbar>
    <Divider />
    <Box
      sx={{
        overflowY: "auto",
        height: "calc(100vh - 144px)",
        overflowX: "hidden",
      }}
    ></Box>
  </Box>
  );
};

export default ScheduledTasks;
