import React, { useContext } from "react";
import Router from "./routing/Router";
import LoaderContext from "../src/context/LoaderContext";
import { Box, CircularProgress } from "@mui/material";
import Loader from "./components/Loader";
// import '@fontsource-variable/sofia-sans';

const App = () => {
  const { isLoading } = useContext(LoaderContext);

  return (
    <>
      {isLoading && <Loader />}
      <Router />
    </>
  );
};

export default App;
