import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import ProtectedLogin from "./ProtectedLogin";
import Register from "../pages/Register";
import NotFound from "../pages/NotFound";
import Layout from "../pages/Layout";
import Buildings from "../pages/Buildings";
import Tasks from "../pages/Tasks";
import ScheduledTasks from "../pages/ScheduledTasks";
import Performance from "../pages/Performance";
import Users from "../pages/Users";
import Landmarks from "../pages/Landmarks";
import Objects from "../pages/Objects";
import Robots from "../pages/Robots";
import Templates from "../pages/Templates";
import TaskHistory from "../pages/History";
import AddRobot from "../pages/AddRobot";
import AddTemplate from "../pages/AddTasks";
import { useAuth } from "../context/AuthContext";
import Login from "../pages/Login";

const Router: React.FC = () => {
  const { login, logout, continueWithGoogle } = useAuth();

 
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedLogin>
            <Login onLogin={login} googleAuth={continueWithGoogle} />
          </ProtectedLogin>
        }
      />
      <Route
        path="/buildings"
        element={
          <ProtectedRoute>
            <Buildings onLogout={logout} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/task-history"
        element={
          <ProtectedRoute>
            <TaskHistory onLogout={logout} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/performance"
        element={
          <ProtectedRoute>
            <Performance onLogout={logout} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <Users onLogout={logout} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/layout"
        element={
          <ProtectedRoute>
            <Layout onLogout={logout} />
          </ProtectedRoute>
        }
      >
        <Route
          path="tasks"
          element={
            <ProtectedRoute>
              <Tasks />
            </ProtectedRoute>
          }
        />
        <Route
          path="scheduled-tasks"
          element={
            <ProtectedRoute>
              <ScheduledTasks />
            </ProtectedRoute>
          }
        />
        <Route
          path="landmarks"
          element={
            <ProtectedRoute>
              <Landmarks />
            </ProtectedRoute>
          }
        />
        <Route
          path="objects"
          element={
            <ProtectedRoute>
              <Objects />
            </ProtectedRoute>
          }
        />
        <Route
          path="robots"
          element={
            <ProtectedRoute>
              <Robots />
            </ProtectedRoute>
          }
        />
        <Route
          path="robots/:id"
          element={
            <ProtectedRoute>
              <Robots />
            </ProtectedRoute>
          }
        />
        <Route
          path="add-robot"
          element={
            <ProtectedRoute>
              <AddRobot />
            </ProtectedRoute>
          }
        />
        <Route
          path="templates"
          element={
            <ProtectedRoute>
              <Templates />
            </ProtectedRoute>
          }
        />
        <Route
          path="add-tasks"
          element={
            <ProtectedRoute>
              <AddTemplate />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="/register" element={<Register />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;


