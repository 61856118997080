import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";
import { Building } from "../../entities/Building";

import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import AutoModeOutlinedIcon from "@mui/icons-material/AutoModeOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";

import { useNavigate } from "react-router-dom";
import PageTitle from "../PageTitle";
import "./index.scss";

const BuildingCard = ({ building }: { building: Building }) => {
  const navigate = useNavigate();

  const navigateTo = (buildingId: number) => {
    navigate("/layout/robots", { state: { buildingId } });
  };

  return (
    <Card className="building-card">
      <CardActionArea onClick={() => navigateTo(building.id)}>
        <CardMedia
          component="img"
          image="assets/hospital.jpg"
          alt={building.name}
        />
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Tasks : {building.statistics.tasks}
          </Typography>
          <PageTitle title={building?.name} />
          <Box className="buildings-info" sx={{ display: "flex", mt: 2 }}>
            {Object.keys(building.statistics)
              .slice(0, 4)
              .map((statistic: any) => (
                <Box key={statistic} className="building-info" sx={{ mr: 2 }}>
                  <Tooltip title={statistic}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {statistic === "robots" ? (
                        <AccountBoxOutlinedIcon />
                      ) : statistic === "landmarks" ? (
                        <AutoModeOutlinedIcon />
                      ) : statistic === "objects" ? (
                        <AutoAwesomeMotionOutlinedIcon />
                      ) : (
                        <InventoryOutlinedIcon />
                      )}
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        className="buildings-info"
                      >
                        {
                          building?.statistics[
                            statistic as keyof typeof building.statistics
                          ] as number
                        }
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              ))}
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions className="building-actions">
        <Button variant="text" aria-label="edit" disabled>
          EDIT
        </Button>
        <Button
          variant="outlined"
          aria-label="edit"
          onClick={() => navigateTo(building.id)}
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
};

export default BuildingCard;
