import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { alpha, styled } from "@mui/material/styles";

export const CommonThemes = {
  scene: {
    //width: document.getElementById("canvas")?.offsetWidth,
    //height: document.getElementById("canvas")?.offsetHeight,
    width: 800,
    height: 450,
    //width: window.innerWidth,
    //height: window.innerHeight,
    container: document.getElementById("canvas"),
  },
  camera: {
    position: { x: 150, y: 75, z: 150 },
  },
  renderer: {
    clearColor: "#F5F5F5",
  },
  SCALE: 0.1,
};

export const FloorThemes = {
  MATERIAL: {
    color: "#E0E0E0",
    opacity: 1,
    transparent: false,
  },
  OFFSET: 5,
  HEIGHT: 80,
};

export const BuildingThemes = {
  MATERIAL: {
    color: "#000000",
    opacity: 0.1,
    transparent: true,
    depthTest: false,
  },
  OFFSET: 600,
  HEIGHT: 500,
};

export const WireThemes = {
  MATERIAL: {
    color: "#5C4433",
    opacity: 0.5,
    transparent: true,
    linewidth: 2,
  },
  OFFSET: 30,
};

export const MaterialThemes = {
  FLOOR: {
    color: "#E0E0E0",
    opacity: 1,
    transparent: false,
  },
  BUILDING: {
    color: "#000000",
    opacity: 0.1,
    transparent: true,
    depthTest: false,
  },
  WIRE: {
    color: "#5C4433",
    opacity: 0.5,
    transparent: true,
    linewidth: 2,
  },
};

export function room(type: number, category?: number) {
  let roomStyle;
  if (!category) {
    switch (type) {
      case 100:
        return {
          color: "#add8e6",
          opacity: 0.8,
          transparent: true,
        };
      case 300: //closed area
        return {
          color: "#add8e6",
          opacity: 0.7,
          transparent: true,
        };
      case 400: //empty shop
        return {
          color: "#add8e6",
          opacity: 0.7,
          transparent: true,
        };
      default:
        break;
    }
  }

  switch (category) {
    case 101: //food
      roomStyle = {
        color: "#add8e6",
        opacity: 0.7,
        transparent: true,
      };
      break;
    case 102: //retail
      roomStyle = {
        color: "#add8e6",
        opacity: 0.7,
        transparent: true,
      };
      break;
    case 103: //toiletry
      roomStyle = {
        color: "#add8e6",
        opacity: 0.7,
        transparent: true,
      };
      break;
    case 104: //parent-child
      roomStyle = {
        color: "#add8e6",
        opacity: 0.7,
        transparent: true,
      };
      break;
    case 105: //life services
      roomStyle = {
        color: "#add8e6",
        opacity: 0.7,
        transparent: true,
      };
      break;
    case 106: //education
      roomStyle = {
        color: "#add8e6",
        opacity: 0.7,
        transparent: true,
      };
      break;
    case 107: //life style
      roomStyle = {
        color: "#add8e6",
        opacity: 0.7,
        transparent: true,
      };
      break;
    case 108: //entertainment
      roomStyle = {
        color: "#add8e6",
        opacity: 0.7,
        transparent: true,
      };
      break;
    case 109: //others
      roomStyle = {
        color: "#add8e6",
        opacity: 0.7,
        transparent: true,
      };
      break;
    default:
      roomStyle = {
        color: "#add8e6",
        opacity: 0.7,
        transparent: true,
      };
      break;
  }
  return roomStyle;
}


export const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[800]
      : theme.palette.grey[200],

  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 500,
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    borderRadius: "50%",
    backgroundColor:
      theme.palette.mode === "light"
        ? alpha(theme.palette.primary.main, 0.25)
        : theme.palette.primary.dark,
    color: theme.palette.mode === "dark" && theme.palette.primary.contrastText,
    padding: theme.spacing(0, 1.2),
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  // Additional styles
  listStyle: "none",
  margin: "4px",
  padding: "0",
  outline: "0",
  backgroundColor: "white",
  borderRadius: "8px",
  border: "1px solid #f2f2f2",
}));