import * as React from "react";
import { Box, Grid, Paper, Portal, Typography, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import DrawerNavigation from "./DrawerNavigation";
import "./index.scss";
import IndoorMap from "./IndoorMap";

const Dashboard = () => {
  return (
    <Box className="dashboard-container">
      <DrawerNavigation />
      <Box className="root-container">
        <Paper className="outlet-container" elevation={1}>
          <Outlet />
        </Paper>
        <Paper className="indoor-map-container" elevation={1}>
          <IndoorMap />
        </Paper>
      </Box>
    </Box>
  );
};

export default Dashboard;
