import * as THREE from "three";
import {
  room,
  FloorThemes,
  BuildingThemes,
  WireThemes,
} from "../utils/Themes";
import { FuncArea } from '../interface/map';
import Landmark from '../images/location.svg'
import Robot from '../images/robot.svg'
import Object from '../images/object.svg'

class Geometry {
  setBuilding(points: Array<THREE.Vector2>) {
    const { MATERIAL, OFFSET, HEIGHT } = BuildingThemes;
    const shape = new THREE.Shape(points);
    const extrudeSettings = { depth: HEIGHT, bevelEnabled: false };
    const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
    const material = new THREE.MeshBasicMaterial(MATERIAL);
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(0, 0, -OFFSET);
    mesh.name = 'building';
    return mesh;
  }

  setFloor(points: Array<THREE.Vector2>) {
    const { OFFSET: BUILDING_OFFSET } = BuildingThemes;
    const { MATERIAL, OFFSET } = FloorThemes;
    const shape = new THREE.Shape(points);
    const geometry = new THREE.ShapeGeometry(shape);
    const material = new THREE.MeshBasicMaterial(MATERIAL);
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(0, 0, -(BUILDING_OFFSET + OFFSET));
    mesh.name = 'floor';
    return mesh;
  }

  setModel(points: Array<THREE.Vector2>, funcArea: FuncArea) {
    const { OFFSET } = BuildingThemes;
    const shape = new THREE.Shape(points);
    const extrudeSettings: THREE.ExtrudeGeometryOptions = {
      depth: 30,
      bevelEnabled: false,
    };
    const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
    const material = new THREE.MeshPhongMaterial(
      room(parseInt(funcArea.Type), funcArea.Category)
    );
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.set(0, 0, -OFFSET);
    mesh.type = "solidroom";
    mesh.name = "floorMesh";
    return mesh;
  }

  // setWire(points: Array<THREE.Vector2>) {
  //   const { OFFSET: BUILDING_OFFSET } = BuildingThemes;
  //   const { MATERIAL, OFFSET } = WireThemes;
  //   const shape = new THREE.Shape(points);
  //   const geometry = shape.createPointsGeometry(0);
  //   const material = new THREE.LineBasicMaterial(MATERIAL);
  //   const wire = new THREE.Line(geometry, material);
  //   wire.position.set(0, 0, -(BUILDING_OFFSET - OFFSET));
  //   return wire;
  // }

  setWire(points: Array<THREE.Vector2>) {
    const { OFFSET: BUILDING_OFFSET } = BuildingThemes;
    const { MATERIAL, OFFSET } = WireThemes;
    const shape = new THREE.Shape(points);
    const geometry = new THREE.BufferGeometry().setFromPoints(shape.getPoints());
    const material = new THREE.LineBasicMaterial(MATERIAL);
    const wire = new THREE.Line(geometry, material);
    wire.position.set(0, 0, -(BUILDING_OFFSET - OFFSET));
    return wire;
  }

  
  setLandmarkNames(message: string, position: [number, number]) {
    const fontface = "Arial";
    const fontsize = 40;
    const fontColor = "#000000";
    const borderThickness = 2;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    context.font = "Bold " + fontsize + "px " + fontface;

    context.fillStyle = fontColor;
    context.fillText(message, borderThickness, fontsize + borderThickness);

    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;

    const spriteMaterial = new THREE.SpriteMaterial({ map: texture, alphaTest: 0.1 });
    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(200, 100, 2.0);
    sprite.position.set(position[0], position[1], 30);

    sprite.name = "landmarkIcon";
    sprite.type = "Sprite";
    return sprite;
  }


  setObjectIcon(position: [number, number]) {
    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load('/assets/object.svg');

    const spriteMaterial = new THREE.SpriteMaterial({
      map: texture,
      transparent: true,
      alphaTest: 0.3
    });


    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.position.set(position[0], position[1], 50);
    sprite.scale.set(30, 30, 1);
    sprite.name = "objectIcon";
    sprite.type = "Sprite";
    return sprite;
  }

  isSvgAnimated(svgContent: string): boolean {
    const animatedTags = ['animate', 'animateTransform', 'animateMotion'];
    return animatedTags.some(tag => svgContent.includes(`<${tag}`));
  }

  setTaskIcon(icon: string, position: [number, number]): Promise<THREE.Object3D> {
    return fetch(icon)
      .then(response => response.text())
      .then(svgContent => {
        const isAnimatedSvg = this.isSvgAnimated(svgContent);

        return new Promise<THREE.Object3D>((resolve) => {
          const svg = new Image();
          svg.src = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgContent);

          svg.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = svg.width;
            canvas.height = svg.height;

            const texture = new THREE.CanvasTexture(canvas);
            const spriteMaterial = new THREE.SpriteMaterial({
              map: texture,
              transparent: true,
              alphaTest: 0.3
            });

            const sprite = new THREE.Sprite(spriteMaterial);
            sprite.position.set(position[0], position[1], 45);
            sprite.scale.set(30, 30, 1);
            sprite.type = "Sprite";

            if (isAnimatedSvg) {
              const animate = () => {
                const currentTime = performance.now();
                const rotation = (currentTime / 1000 * 360) % 360;
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.save();
                context.translate(canvas.width / 2, canvas.height / 2);
                context.rotate((rotation * Math.PI) / 180);
                context.translate(-canvas.width / 2, -canvas.height / 2);
                context.drawImage(svg, 0, 0);
                context.restore();

                texture.needsUpdate = true;
                requestAnimationFrame(animate);
              };
              animate();
            } else {
              context.drawImage(svg, 0, 0);
              texture.needsUpdate = true;
            }

            resolve(sprite);
          };
        });
      })
      .catch(error => {
        console.error('Error loading SVG:', error);
        return new THREE.Object3D();
      });
  }

  setRobotIcon(x: number, y: number, z: number) {
    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load('/assets/robot.svg');

    const spriteMaterial = new THREE.SpriteMaterial({
      map: texture,
      transparent: true,
      alphaTest: 0.3
    });


    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.position.set(x, y, z);
    sprite.scale.set(40, 40, 1);
    sprite.type = "Sprite";
    return sprite;
  }
}

const geometry = new Geometry();
export default geometry;