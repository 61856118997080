import { Building } from "../entities/Building";

class BuildingService {
  private data: Building[] = [];

  constructor() {
    this.loadData().then((data) => {
      this.data = data;
    });
  }
  async loadData(): Promise<Building[]> {
    try {
      const response = await fetch("/data/buildings.json");
      this.data = await response.json();
      return this.data;
    } catch (error) {
      console.error("Error loading data:", error);
      return [];
    }
  }

  async getData(): Promise<Building[]> {
    if (!this.data || !this.data.length) {
      return this.loadData();
    } else {
      return Promise.resolve(this.data);
    }
  }

  setBuildingIdToLocalStorage(id: string): void {
    localStorage.setItem("building_id", id);
  }

  clearBuildingIdFromLocalStorage() {
    localStorage.removeItem("building_id");
  }

  getBuildingIdFromLocalStorage() {
    const buildingId = localStorage.getItem("building_id");
    return buildingId ? JSON.parse(buildingId) : null;
  }
  async getBuildingNameById(id: number): Promise<string | undefined> {
    if (!this.data || !this.data.length) {
      await this.loadData();
    }
    const building = this.data.find((item) => item.id === id);
    if (building) {
      return building.name;
    } else {
      throw new Error(`Building not found for id: ${id}`);
    }
  }

  getDataById(id: number): Promise<Building[]> {
    if (!this.data || !this.data.length) {
      return this.loadData();
    }
    return Promise.resolve(this.data.filter((item) => item.id === id));
  }

  addItem(item: Building): void {
    this.data.push(item);
  }

  deleteItem(id: number): void {
    this.data = this.data.filter((item) => item.id !== id);
  }

  editItem(id: number, updatedItem: Partial<Building>): void {
    this.data = this.data.map((item) =>
      item.id === id ? { ...item, ...updatedItem } : item
    );
  }
}

const buildingService = new BuildingService();
export default buildingService;
