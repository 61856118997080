import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Collapse,
  IconButton,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import "./index.scss";
import AppHeader from "../../components/AppHeader";
import DrawerNavigation from "../Dashboard/DrawerNavigation";
import PageTitle from "../../components/PageTitle";
import HistoryService from "../../services/HistoryService";
import { Details, History } from "../../entities/History";

const TaskHistory = ({onLogout} : any) => {
  const [openRow, setOpenRow] = useState<number>();
  const [history, setHistory] = useState<History[]>([]);

  const handleRowClick = (taskId: number) => {
    setOpenRow(openRow === taskId ? 0 : taskId);
  };

  React.useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    try {
      const response = await HistoryService.getHistory();
      console.log("🚀 ~ fetchHistory ~ response:", response);
      setHistory(response);
    } catch (error) {
      console.error("Error fetching robots:", error);
    }
  };

  return (
    <>
      <AppHeader onLogout={onLogout}/>
      <DrawerNavigation />
      <Box sx={{ margin: "82px" }} className="history-container">
        <PageTitle title="Task History"></PageTitle>
        <TableContainer component={Paper} elevation={0}>
          <Table aria-label="task history table">
            <TableHead>
              <TableRow className="table-header">
                <TableCell />
                <TableCell className="table-header-cell">ID</TableCell>
                <TableCell className="table-header-cell">Name</TableCell>
                <TableCell className="table-header-cell">Tag</TableCell>
                <TableCell className="table-header-cell">Robot ID</TableCell>
                <TableCell className="table-header-cell">Robot Name</TableCell>
                <TableCell className="table-header-cell">Start Time</TableCell>
                <TableCell className="table-header-cell">End Time</TableCell>
                <TableCell className="table-header-cell">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((task: any) => (
                <React.Fragment key={task?.id}>
                  <TableRow
                    onClick={() => handleRowClick(task?.id)}
                    className="table-row"
                  >
                    <TableCell>
                      <IconButton aria-label="expand row" size="small">
                        {openRow === task?.id ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>{task.id}</TableCell>
                    <TableCell>{task.name}</TableCell>
                    <TableCell>{task.tag}</TableCell>
                    <TableCell>{task.robotId}</TableCell>
                    <TableCell>{task.robotName}</TableCell>
                    <TableCell>{task.startTime}</TableCell>
                    <TableCell>{task.endTime}</TableCell>
                    <TableCell>
                      <Chip
                        label={task.status}
                        color={
                          task.status === "Completed"
                            ? "success"
                            : task.status === "Failed"
                            ? "error"
                            : "default"
                        }
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow className="details-row">
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={9}
                    >
                      <Collapse
                        in={openRow === task?.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Typography variant="h6" gutterBottom component="div">
                            Details
                          </Typography>
                          <Table size="small" aria-label="details">
                            <TableHead>
                              <TableRow>
                                <TableCell>Lifecycle Event ID</TableCell>
                                <TableCell>Event Description</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Floor</TableCell>
                                <TableCell>Object</TableCell>
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                                <TableCell>Duration</TableCell>
                                <TableCell>Status</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {task.details.map(
                                (detail: Details, index: number) => (
                                  <TableRow key={index} className="details-row">
                                    <TableCell>
                                      {detail.lifecycleEventId}
                                    </TableCell>
                                    <TableCell>
                                      {detail.eventDescription}
                                    </TableCell>
                                    <TableCell>{detail.location}</TableCell>
                                    <TableCell>{detail.floor}</TableCell>
                                    <TableCell>{detail.object}</TableCell>
                                    <TableCell>{detail.startTime}</TableCell>
                                    <TableCell>{detail.endTime}</TableCell>
                                    <TableCell>{detail.duration}</TableCell>
                                    <TableCell>
                                      <Chip
                                        label={detail.status}
                                        color={
                                          detail.status === "Completed"
                                            ? "success"
                                            : "error"
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default TaskHistory;
