
import {
  Alert,
  Button,
  Card,
  Container,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import nandiLogo from "../../nandi_logo.png";
import "../../styles.scss";
import "./index.scss";
import { useAuth } from "../../context/AuthContext";



const Login = ({ onLogin, googleAuth }: any) => {
  const { loginError } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [formValid, setFormValid] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");

  // Regular expression for basic email validation
  const emailRegex = /\S+@\S+\.\S+/;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    onLogin({ email, password });
    console.log("🚀 ~ Login ~ loginError:", loginError);
  };

  const handleGoogleAuth = async () => {
    await googleAuth();
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
    setFormValid(emailRegex.test(value) && value !== "" && password !== "");
    // Show email error only when email is not empty and doesn't match the regex
    if (value === "" || emailRegex.test(value)) {
      setEmailError("");
    } else {
      setEmailError("Invalid email format");
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPassword(value);
    setFormValid(emailRegex.test(email) && email !== "" && value !== "");
  };

  return (
    <Container className="login-container" maxWidth="sm">
      <Card
        elevation={0}
        sx={{
          borderRadius: "4px",
          maxWidth: "400px",
          backgroundColor: "white",
        }}
      >
        <form onSubmit={handleSubmit} style={{ padding: "30px" }}>
          <div className="logo-container">
            <img className="nandi-logo" src={nandiLogo} alt="Nandi Logo" />
          </div>
          <Typography className="login-header" variant="h5" component="h5">
            Sign in
          </Typography>
          <TextField
            id="email"
            name="email"
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            value={email}
            onChange={handleEmailChange}
            error={emailError !== ""}
            helperText={emailError}
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            value={password}
            onChange={handlePasswordChange}
          />
          <Button
            sx={{ marginTop: "16px" }}
            variant="contained"
            disableElevation
            type="submit"
            fullWidth
            className="contained-button"
            size="small"
            disabled={!formValid}
          >
            Login
          </Button>
          {loginError && (
            <Typography color={"error"}>Invalid email or password</Typography>
          )}
          <Typography className="or" variant="body2" component="p">
            Or
          </Typography>
          <Button
            sx={{ marginTop: "16px" }}
            variant="contained"
            disableElevation
            type="button"
            fullWidth
            className="contained-button"
            size="small"
            onClick={handleGoogleAuth}
            disabled
          >
            <FcGoogle size={25} className="googleLogo" /> Continue With Google
          </Button>
          <Typography color={"error"}>Currently Unavailable</Typography>
          {/* <Typography className="create-account" variant="body2" component="p">
            New user? <a href="/register">Create an account</a>
          </Typography> */}
        </form>
      </Card>
    </Container>
  );
};

export default Login;