import React, { useEffect, useState } from "react";
import { Template } from "../../../entities/Task";
import { TextField, Autocomplete, Paper } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useTemplates } from "../../../hooks/useTemplates";
import { useLocation } from "react-router-dom";
import TemplateDetails from "../../../components/TemplateCard/TemplateDetails";
import { useIndoorMapContext } from "../../../context/IndoorMapContext";

const SelectTemplate = () => {
  const { selectedFloorId } = useIndoorMapContext();
  const { setValue } = useFormContext();
  const templates: Template[] = useTemplates();
  const { state } = useLocation();
  const [selectedTemplate, setSelectedTemplate] = useState<{
    id: number;
    name: string;
  } | null>(null);

  useEffect(() => {
    if (state && state.templateId) {
      // Find the template that matches the templateId from state
      const selected = templates.find(
        (template) => template.id === state.templateId
      );
      if (selected) {
        setSelectedTemplate(selected);
        // Set the form value
        setValue("template_id", selected.id);
      }
    }
  }, [state, templates, setValue]);

  useEffect(() => {
    setSelectedTemplate({ id: 0, name: "NEW TASK" });
    setValue("template_id", 0);
  }, [selectedFloorId, setValue]);

  return (
    <>
      <Autocomplete
        options={[{ id: 0, name: "NEW TASK" }, ...templates]}
        getOptionLabel={(option) => option.name}
        value={selectedTemplate || { id: 0, name: "NEW TASK" }}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Select Template or Create New Task"
            variant="outlined"
          />
        )}
        onChange={(event, value) => {
          setSelectedTemplate(value);
          setValue("template_id", value?.id);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={{ my: 2 }}
        clearIcon={null}
      />
      {selectedTemplate ? (
        <Paper sx={{ p: 2 }} elevation={0}>
          <TemplateDetails template={selectedTemplate} />
        </Paper>
      ) : null}
    </>
  );
};

export default SelectTemplate;
