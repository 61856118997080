import User from "../entities/User";

const UserService = {
  getUser: (email: string, password: string) => {
    if (email === "superadmin@drona.com" && password === "superadmin123") {
      return { role: "super-admin", active: true };
    } else if (email === "admin@drona.com" && password === "admin123") {
      return { role: "admin", active: true };
    } else if (email === "staff@drona.com" && password === "staff123") {
      return { role: "staff", active: true };
    } else {
      return { role: null, active: false };
    }
  },

  setUserToLocalStorage(user: User) {
    localStorage.setItem("current-user", JSON.stringify(user));
  },

  getUserFromLocalStorage() {
    const user = localStorage.getItem("current-user");
    return user ? JSON.parse(user) : null;
  },

  clearUserFromLocalStorage() {
    localStorage.removeItem("current-user");
  },

  getUserDetails: async () => {
    try {
      const response = await fetch(`/data/user.json`);
      const data = await response.json();
      const storageData = localStorage.getItem("users");
      let storageDataParsed = [];
      if (storageData) {
        try {
          storageDataParsed = JSON.parse(storageData);
        } catch (error) {
          console.error("Error parsing storage data:", error);
        }
      }
      return [...data, ...storageDataParsed];
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  addUser: async (user: User): Promise<void> => {
    try {
      const storageData = localStorage.getItem("users");
      let users: User[] = [];
      if (storageData) {
        try {
          users = JSON.parse(storageData);
        } catch (error) {
          console.error("Error parsing storage data:", error);
        }
      }
      users.push(user);
      localStorage.setItem("users", JSON.stringify(users));
    } catch (error) {
      console.error("Error adding user:", error);
      throw error;
    }
  },

  editUser: async (updatedUser: User): Promise<void> => {
    try {
      const storageData = localStorage.getItem("users");
      let users: User[] = [];
      if (storageData) {
        try {
          users = JSON.parse(storageData);
        } catch (error) {
          console.error("Error parsing storage data:", error);
        }
      }
      users = users.map((user) => (user.id === updatedUser.id ? updatedUser : user));
      localStorage.setItem("users", JSON.stringify(users));
    } catch (error) {
      console.error("Error editing user:", error);
      throw error;
    }
  },

  deleteUser: async (userId: number): Promise<void> => {
    try {
      const storageData = localStorage.getItem("users");
      let users: User[] = [];
      if (storageData) {
        try {
          users = JSON.parse(storageData);
        } catch (error) {
          console.error("Error parsing storage data:", error);
        }
      }
      users = users.filter((user) => user.id !== userId);
      localStorage.setItem("users", JSON.stringify(users));
    } catch (error) {
      console.error("Error deleting user:", error);
      throw error;
    }
  },
};

export default UserService;
