import React from "react";
import { Template } from "../../../entities/Task";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const TemplateHeader = ({ template }: any) => {
  return (
    <Box className="heading-box">
      <Box className="title-box">
        <Typography variant="h2" color={"theme.palette.text.primary"}>
          {template?.name}
        </Typography>
      </Box>
      <Typography variant="subtitle1">{template?.description}</Typography>
    </Box>
  );
};

export default TemplateHeader;
