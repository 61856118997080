import { useIndoorMapContext } from "../context/IndoorMapContext";
import { Task, TaskStep, TaskTrigger, Template } from "../entities/Task";
import { getCurrentUser } from "../utils/generic-functions";
import buildingService from "./BuildingsService";
import tempUserService from "./TempUserService";
import templateService from "./TemplateService";

class TaskService {
  private data: Task[] = [];

  constructor() {
    this.loadData().then((data) => {
      this.data = data;
    });
  }

  async loadData(): Promise<Task[]> {
    const buildingId = buildingService.getBuildingIdFromLocalStorage();
    try {
      const response = await fetch("/data/tasks.json");
      this.data = await response.json();
      if (buildingId) {
        this.data = this.data.filter(
          (item: Task) => item.building_id === buildingId
        );
      }

      return this.data;
    } catch (error) {
      console.error("Error loading data:", error);
      return [];
    }
  }

  async getData(): Promise<Task[]> {
    if (!this.data || !this.data.length) {
      return this.loadData();
    } else {
      return Promise.resolve(this.data);
    }
  }

  updateSteps(steps: any) {
    const updatedSteps: TaskStep[] = [];
    for (let i = 0; i < steps.length; i++) {
      let stepObj: TaskStep = {
        id: Number(steps[i].id),
        type: String(steps[i].type).trim(),
        step_landmark: steps[i].step_landmark,
        step_wait_time: steps[i]?.step_wait_time,
        start_time: "",
        end_time: "",
        status: "pending", // Initially all steps are pending
        status_message: "waiting", // Reason for failure or waiting
      };
      updatedSteps.push(stepObj);
    }
    return updatedSteps;
  }

  addItem(item: any, floorId: number): void {
    console.log("~ 🚀 called from task service line = 139", item);
    const templateId = item?.template_id;
    const userId = tempUserService.getLoggedUser().id;
    const buildingId = buildingService.getBuildingIdFromLocalStorage();
    // trigger.schedule_time = trigger?.schedule_time;

    console.log("~ 🚀 Form data to service is:", item);

    const existingTemplate = templateService.checkExistingTemplate(templateId);
    if (!existingTemplate) {
      console.log("NEW TEMPLATE CREATING.....");
      const id = templateService.getLength() + 1;
      const name = item.name;
      const formsteps = item.steps;
      const newTaskId = Number(this.data.length + 1);

      const newTemplateObj: Template = {
        id,
        name,
        steps: formsteps,
        created_user_id: userId,
        executed_count: 0,
        floor_id: floorId,
        building_id: buildingId,
      };
      // templateService.addItem(newTemplateObj);

      const updatedSteps = this.updateSteps(newTemplateObj.steps);

      const triggerObj: TaskTrigger = {
        id: Math.floor(Math.random() * 900) + 100,
        type: item.type,
        task_id: newTaskId,
        step_id: updatedSteps[0].id,
        schedule_time: updatedSteps[0].start_time,
      };

      const newTaskObj: Task = {
        id: newTaskId,
        template_id: Number(newTemplateObj.id),
        trigger: triggerObj,
        start_time: item.schedule_time,
        end_time: "",
        robot_id: "Robot123", // We have to add dynamically after adding robot
        floor_id: floorId,
        steps: updatedSteps,
        status: "pending",
        initiated_user_id: Number(userId),
        building_id: buildingId,
      };

      this.data.push(newTaskObj);
      templateService.incrementExecutionCount(newTemplateObj.id);
    } else {
      const newTaskId = Number(this.data.length + 1);
      const template: Template = templateService.getTemplateById(templateId);
      const updatedSteps = this.updateSteps(template.steps);

      const triggerObj: TaskTrigger = {
        id: Math.floor(Math.random() * 900) + 100,
        type: item.type,
        task_id: newTaskId,
        step_id: updatedSteps[0].id,
        schedule_time: updatedSteps[0].start_time,
      };

      const newTaskObj: Task = {
        id: newTaskId,
        template_id: Number(templateId),
        trigger: triggerObj,
        start_time: item.schedule_time,
        end_time: item.end_time,
        robot_id: "Robot123", // We have to add dynamically after adding robot
        floor_id: template.floor_id,
        steps: updatedSteps,
        status: "pending",
        initiated_user_id: Number(userId),
        building_id: buildingId,
      };

      this.data.push(newTaskObj);
      templateService.incrementExecutionCount(templateId);
    }
  }

  runTask(id: number, data: any) {
    const template = templateService.getTemplateById(id);
    if (template) {
      const newTaskId = Number(this.data.length + 1);
      const updatedSteps = this.updateSteps(template.steps);

      const triggerObj: TaskTrigger = {
        id: Math.floor(Math.random() * 900) + 100,
        type: data.type,
        task_id: newTaskId,
        step_id: updatedSteps[0].id,
        schedule_time: updatedSteps[0].start_time,
      };

      const newTaskObj: Task = {
        id: newTaskId,
        template_id: template.id,
        trigger: triggerObj,
        start_time: data.schedule_time,
        end_time: data.end_time,
        robot_id: "Robot123", // We have to add dynamically after adding robot
        floor_id: template.floor_id,
        steps: updatedSteps,
        status: "pending",
        initiated_user_id: getCurrentUser().id,
        building_id: template.building_id,
      };

      this.data.push(newTaskObj);
      templateService.incrementExecutionCount(template.id);
      console.log("_________", this.data);
      console.log("________", templateService.getLength());
    }
  }

  deleteItem(id: number): void {
    this.data = this.data.filter((item) => item.id !== id);
  }

  editItem(id: number, updatedItem: Partial<Task>): void {
    this.data = this.data.map((item) =>
      item.id === id ? { ...item, ...updatedItem } : item
    );
  }

  async getTaskById(id: number): Promise<Task[] | []> {
    try {
      const task = this.data.filter((task) => task.id === id);
      return Promise.resolve(task);
    } catch (error) {
      console.log("Error while finding tasks by id", error);
      return Promise.resolve([]);
    }
  }

  async getTaskByRobotId(id: string): Promise<Task[] | []> {
    try {
      const task = this.data.filter((task) => task.robot_id === id);
      return Promise.resolve(task);
    } catch (error) {
      console.log("Error while finding tasks by robot id", error);
      return Promise.resolve([]);
    }
  }

  async getTaskByUserId(id: number): Promise<Task[] | []> {
    try {
      const task = this.data.filter((task) => task.initiated_user_id === id);
      return Promise.resolve(task);
    } catch (error) {
      console.log("Error while finding tasks by robot id", error);
      return Promise.resolve([]);
    }
  }

  async getTasksByStatus(status: string): Promise<Task[] | []> {
    try {
      const task = this.data.filter((task) => task.status === status);
      return Promise.resolve(task);
    } catch (error) {
      console.log("Error while finding tasks by robot id", error);
      return Promise.resolve([]);
    }
  }

  async addDataApi(data, floorId, buildingId) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      const url = "https://api.nandi.autos/create/task";
      const response = await fetch(url, requestOptions);
      console.log("Api call respone", response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      console.log("Response data", responseData);
      if (responseData) {

        // CREATING AND SAVING NEW TEMPLATE WITH API RESPONSE
        const newTemplateObj: Template = {
          id: responseData._id,
          name: responseData.name,
          created_user_id: getCurrentUser().id,
          executed_count: 0,
          floor_id: floorId,
          building_id: buildingId,
          steps: templateService.updateTemplateSteps(responseData.steps)
        }
        templateService.addApiResponseToTemplate(newTemplateObj)

        const updatedSteps = this.updateSteps(responseData.steps)
        const newTaskId = Number(this.data.length + 1)
        const triggerObj: TaskTrigger = {
          id: Math.floor(Math.random() * 900) + 100,
          type: responseData.type,
          task_id: newTaskId,
          step_id: updatedSteps[0].id,
          schedule_time: updatedSteps[0].start_time
        }

        // CREATING AND SAVING NEW TASK WITH API RESPONSE
        const newTaskObj: Task = {
          id: newTaskId,
          template_id: newTemplateObj.id,
          trigger: triggerObj,
          start_time: responseData.schedule_time,
          end_time: "",
          initiated_user_id: getCurrentUser().id,
          robot_id: "ARM001", //to be added dynamiclly
          steps: updatedSteps,
          status: "pending",
          building_id: buildingId,
          floor_id: floorId
        }

        this.data.push(newTaskObj)
      }
      console.log("Task created successfully with api data:", responseData);
    } catch (error) {
      console.error("Error creating task:", error);
    }
  }

  async testApi() {
    try {
      const response = await fetch("https://api.nandi.autos/ping", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        throw new Error("API request failed");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Failed to test API: " + error.message);
    }
  }
}

const taskService = new TaskService();
export default taskService;
