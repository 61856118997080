import { Backdrop, Button, Fade, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PageTitle from "../components/PageTitle";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Landmark } from "../entities/Landmark";
import { getCurrentUser } from "../utils/generic-functions";

const AddLandmarkModal = ({
  showAddLandmarkModal,
  handleLandmarkModalClose,
  event,
  landmark = null,
  handleSaveLandmark,
  mapId,
  floorId,
}: {
  showAddLandmarkModal: boolean;
  handleLandmarkModalClose: () => void;
  event: any;
  landmark?: Landmark | null;
  mapId: any;
  floorId: number;
  handleSaveLandmark: (data: any) => void;
}) => {
  console.log("Recieved mapId on modal",mapId)
  const currentUser = getCurrentUser();
  const [modalPosition, setModalPosition] = React.useState({
    top: "50%",
    left: "50%",
  });

  const methods = useForm<Landmark>({
    defaultValues: {
      name: landmark?.name ? landmark?.name : "",
      floor_id: landmark?.floor_id ? landmark?.floor_id : floorId,
      map_unit_id: landmark?.map_unit_id ? landmark?.map_unit_id : mapId,
    },
  });

  const { handleSubmit, control } = methods;

  const style = {
    position: "absolute" as "absolute",
    top: modalPosition.top,
    left: modalPosition.left,
    transform: `translate(-${modalPosition.top}%, -${modalPosition.left}%)`,
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    p: 2,
  };


  React.useEffect(() => {
    setModalPosition({ top: `${event.clientY}px`, left: `${event.clientX}px` });
  }, [event, landmark]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={showAddLandmarkModal}
      onClose={handleLandmarkModalClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={showAddLandmarkModal}>
        <Box sx={style}>
          <PageTitle title="Landmark details" />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSaveLandmark)}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field, formState: { errors } }) => (
                  <TextField
                    {...field}
                    id="outlined-basic"
                    label="Landmark name"
                    variant="outlined"
                    sx={{ width: "100%", my: 2 }}
                    size="small"
                    disabled={currentUser.role === "staff"}
                    error={!!errors.name}
                    helperText={errors.name ? "Name is required" : ""}
                  />
                )}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  margin: "10px 0 0 0",
                }}
              >
                <Button
                  fullWidth
                  className="outlined-button"
                  variant="text"
                  size="small"
                  onClick={handleLandmarkModalClose}
                  sx={{ mr: 1 }}
                >
                  Close
                </Button>

                <Button
                  fullWidth
                  className="contained-button"
                  variant="contained"
                  type="submit"
                  size="small"
                  disabled={currentUser?.role === "staff"}
                >
                  {landmark ? "Update" : "Save"}
                </Button>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddLandmarkModal;
