const PerformanceService = {
    getPerformance: async () => {
      try {
        const response = await fetch(`/data/performance.json`);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  };
  
  export default PerformanceService;
  