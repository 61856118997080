import { Landmark } from "../entities/Landmark";
import buildingService from "./BuildingsService";

class LandmarkService {
  private data: Landmark[] = [];

  constructor() {
    this.loadData().then((data) => {
      this.data = data;
    });
  }

  async loadData(): Promise<Landmark[]> {
    const buildingId = buildingService.getBuildingIdFromLocalStorage();
    try {
      const response = await fetch("/data/landMarks.json");
      if (buildingId) {
        this.data = this.data.filter(
          (item: Landmark) => item.building_id === buildingId
        );
      }
      this.data = await response.json();
      return this.data;
    } catch (error) {
      console.error("Error loading data:", error);
      return [];
    }
  }

  async getData(): Promise<Landmark[]> {
    if (!this.data || !this.data.length) {
      return this.loadData();
    } else {
      return Promise.resolve(this.data);
    }
  }

  async getDataById(id: number): Promise<Landmark | undefined> {
    return this.data.find((item) => item.id === id);
  }

  async getDataByFloorId(id: number): Promise<Landmark[]> {
    return this.data.filter((item) => item.floor_id === id);
  }

  async getDataByMapUnitId(id: number): Promise<Landmark | null> {
    return this.data.find((item) => item.map_unit_id === id) || null;
  }

  async addItem(item: Landmark): Promise<Landmark> {
    item.id =
      Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
    this.data.push(item);
    console.log("New landmark added:", item);
    await this.saveData();
    return item;
  }

  async deleteItem(id: number): Promise<void> {
    this.data = this.data.filter((item) => item.id !== id);
    await this.saveData();
  }

  async editItem(id: number, updatedItem: Landmark): Promise<void> {
    console.log("called edit from landmark service class");
    if (!id) {
      console.log("editing.....");
      const index = this.data.findIndex((item) => item.id === updatedItem.id);
      if (index !== -1) {
        if (updatedItem.name !== undefined) {
          this.data[index].name = updatedItem.name;
        }
      } else {
        console.log("creating.....");
        updatedItem.id =
          this.data.length > 0
            ? Math.max(...this.data.map((l) => l.id)) + 1
            : 1;
        this.data.push(updatedItem);
        console.log("new data is", updatedItem);
      }
      await this.saveData();
    } else {
      console.error(`Landmark with id ${id} not found.`);
    }
  }

  async getDataByBuildingId(buildingId: number): Promise<Landmark[]> {
    if (!this.data || !this.data.length) {
      await this.loadData();
    }
    const landMarks = this.data.filter(
      (item) => item.building_id === buildingId
    );
    return Promise.resolve(landMarks);
  }

  async saveData(): Promise<void> {
    console.log("Data saved:", this.data);
  }

  landMarksIds(): number[] {
    return this.data.map((data) => data.map_unit_id);
  }

  getLandmarkNameById(id: number): string {
    console.log(
      "🚀 ~ LandmarkService ~ getLandmarkNameById ~ this.data:",
      this.data
    );

    const landmark = this.data.find((item) => item.map_unit_id === id);
    console.log(
      "🚀 ~ LandmarkService ~ getLandmarkNameById ~ landmark:",
      landmark
    );
    if (landmark) {
      return landmark.name;
    } else {
      console.log("No landmark found with id:", id);
      return "";
    }
  }

  getMapUnitIdByLandmarkName(name: string) {
    const landmark = this.data.find((item) => item.name === name);
    if (landmark) {
      return landmark.map_unit_id;
    } else {
      console.log("No landmark found with ", name);
      return;
    }
  }

  getMapUnitIdByFloorId(id: number): number[] {
    const ids: number[] = [];
    this.data.forEach((item) => {
      if (item.floor_id === id) {
        ids.push(item.map_unit_id);
      }
    });
    return ids;
  }

  getNameByMapUnitId(id: number) {
    const landMark = this.data.find((item) => item.map_unit_id === id)
    return landMark.name
  }
}

const landmarkService = new LandmarkService();
export default landmarkService;
