import { Box, Button, Divider, Grid, Toolbar, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { SimpleTreeView } from "@mui/x-tree-view";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import { Robot } from "../../entities/Robot";
import { useRobots } from "../../hooks/useRobots";
import useMap from "../../map/useMap";
import "../../styles.scss";
import { CustomTreeItem } from "../../utils/Themes";
import { groupRobotsByFloorId } from "../../utils/generic-functions";
import "./index.scss";
import robotService from "../../services/RobotService";
import { useEffect, useState } from "react";
import EditRobotModal from "./EditRobotModal";
import { useIndoorMapContext } from "../../context/IndoorMapContext";
import MapIcons from "../../map/MapIcons";

const Robots = () => {
  const robots: Robot[] = useRobots();
  const { editRobot, selectedFloorId } = useIndoorMapContext();
  const navigate = useNavigate();
  const groupedRobots = groupRobotsByFloorId(robots);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedRobot, setSelectedRobot] = useState<any>(null);
  const { onShowFloorById } = useMap();
  const { showRobotIcons, removeRobotIcons } = MapIcons();

  const navigateTo = () => {
    navigate("/layout/add-robot");
  };

  const displayRobotIcons = (floorId: number) => {
    const datas = robots.filter((item) => item.floor_id === floorId);
    console.log("Called with", datas.length, "Robots");
    showRobotIcons(datas.length);
  };

  const handleLabelClick = (floorId: number) => {
    onShowFloorById(floorId);
    displayRobotIcons(floorId);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedRobot(null);
  };

  const handleRobotClick = async (robot: Robot) => {
    const data = await robotService.getDataById(robot.id);
    setSelectedRobot(data);
    setShowModal(true);
  };

  const handleEditRobot = (data: any) => {
    editRobot(data);
    robotService.editItem(data);
    setSelectedRobot(null);
    setShowModal(false);
  };

  useEffect(() => {
    if (
      window.location.pathname === "/layout/robots" ||
      window.location.pathname === "/layout/add-robot"
    ) {
      displayRobotIcons(selectedFloorId);
    }

    return () => {
      if (window.location.pathname !== "/layout/robots") {
        removeRobotIcons();
      }
      if (window.location.pathname !== "/layout/add-robot") {
        removeRobotIcons();
      }
    };
  }, [selectedFloorId, window.location.pathname, robots.length]);

  return (
    <Box
      component={"div"}
      className="robots_container"
      sx={{ minWidth: "400px" }}
    >
      <Toolbar className="robots_header">
        <PageTitle title="ROBOTS" />
        <Button
          variant="contained"
          className="contained-button"
          onClick={navigateTo}
          size="small"
        >
          Add Robot
        </Button>
      </Toolbar>
      <Divider />
      <Box className="robots_list">
        <Grid sx={{ p: 0.5 }}>
          <SimpleTreeView>
            {Object.entries(groupedRobots).map(([floorId, robots]) => (
              <CustomTreeItem
                key={`floor-${floorId}`}
                itemId={`floor-${floorId}`}
                label={`Floor ${floorId}`}
                onClick={() => handleLabelClick(Number(floorId))}
              >
                {robots.map((robot) => (
                  <CustomTreeItem
                    key={`robot-${robot.id}-floor-${floorId}`}
                    itemId={`robot-${robot.id}-floor-${floorId}`}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {robot.name}
                        <IconButton
                          sx={{ ml: 1 }}
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRobotClick(robot);
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    }
                  />
                ))}
              </CustomTreeItem>
            ))}
          </SimpleTreeView>
        </Grid>
      </Box>
      {showModal && window.location.pathname === "/layout/robots" && (
        <EditRobotModal
          showModal={showModal}
          handleModalClose={handleModalClose}
          handleEditRobot={handleEditRobot}
          robot={selectedRobot}
        />
      )}
    </Box>
  );
};

export default Robots;
